import { BaseEntity, Entity as DecribeEntity } from '@lib/entity';

import type { EgTopic } from '@modules/types/graphql';

export type Entity = EgTopic | null;

@DecribeEntity('TopicEntity')
class TopicEntity extends BaseEntity {
    id: string;
    name: string;

    constructor(topic: Entity) {
        super(topic);

        this.id = topic?.id ?? '';
        this.name = topic?.name ?? '';
    }

    exists() {
        const exists = this.id !== '';

        return exists;
    }
}

export { TopicEntity };
