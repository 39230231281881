import { gql } from '@apollo/client';

export const GetCourseQuery = gql`
    query GetCourse($id: ID!) {
        getOLCourse(id: $id) {
            id
            name
            certificateUrl
            test {
                questionsCount
                duration
            }
        }
    }
`;
