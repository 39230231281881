import { EntityList } from '@lib/entity';
import { EgLessonsListItemEntity } from './eg-lessons-list-item-entity';

import type { Entity } from './eg-lessons-list-item-entity';

class EgLessonsListItemEntityList extends EntityList<EgLessonsListItemEntity> {
    constructor(items: Entity[] | null, count?: number | null) {
        super(EgLessonsListItemEntity, items, count);
    }
}

export { EgLessonsListItemEntityList };
