import { BaseEntity, Entity as DecribeEntity } from '@lib/entity';

import type { EgLessonDisciplineScore } from '@modules/types/graphql';

export type Entity = Partial<EgLessonDisciplineScore> | null;

@DecribeEntity('LessonDisciplineScoreEntity')
class LessonDisciplineScoreEntity extends BaseEntity {
    id: string;
    lessonId: string;
    score: number | null;
    createdAt: string;
    updatedAt: string;

    constructor(lessonDisciplineScore: Entity) {
        super(lessonDisciplineScore);

        this.id = lessonDisciplineScore?.id ?? '';
        this.lessonId = lessonDisciplineScore?.lessonId ?? '';
        this.score = lessonDisciplineScore?.score ?? null;
        this.createdAt = lessonDisciplineScore?.createdAt ?? '';
        this.updatedAt = lessonDisciplineScore?.updatedAt ?? '';
    }
}

export { LessonDisciplineScoreEntity };
