import { BaseEntity, Entity as DecribeEntity, RelationList } from '@lib/entity';

import { TopicEntity, TopicEntityList } from '@modules/diary/subjects/entities';

import type { EgSubject } from '@modules/types/graphql';

export type Entity = EgSubject | null;

@DecribeEntity('EgSubjectEntity')
class EgSubjectEntity extends BaseEntity {
    id: string;
    name: string;
    shortName: string;
    description: string;
    moduleId: string;
    color: EgSubject['color'];
    createdAt: EgSubject['createdAt'];

    @RelationList(() => TopicEntityList)
    topics: TopicEntity[];

    constructor(subject: Entity) {
        super(subject);

        this.id = subject?.id ?? '';
        this.name = subject?.name ?? '';
        this.shortName = subject?.shortName ?? '';
        this.description = subject?.description ?? '';
        this.moduleId = subject?.moduleId ?? '';
        this.color = subject?.color ?? null;
        this.createdAt = subject?.createdAt ?? '';
    }

    exists() {
        const exists = this.id !== '';

        return exists;
    }
}

export { EgSubjectEntity };
