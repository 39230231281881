import { createStore, createEvent } from 'effector';
import { useStore } from 'effector-react';

// type:action -> bulk:create | bulk:remove | etc
type ScheduleTypeActionStore = string;

const setScheduleTypeAction = createEvent<ScheduleTypeActionStore>('set schedule type action');
const resetScheduleTypeAction = createEvent('reset schedule type action');
const $scheduleTypeActionStore = createStore<ScheduleTypeActionStore>('');

const useScheduleTypeAction = (): ScheduleTypeActionStore => useStore($scheduleTypeActionStore);

export {
    $scheduleTypeActionStore,
    useScheduleTypeAction,
    setScheduleTypeAction,
    resetScheduleTypeAction,
};
