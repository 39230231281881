export {
    FileDocFragment,
    VideoFileDocFragment,
    FileInfoFragment,
    TranslationFragment,
} from './fragments';
export {
    GetLocationsQuery,
    GetSchoolTypesQuery,
    GetSchoolNumbersQuery,
    GetRegionsQuery,
} from './queries';
export { UploadFileRestMutation, UpdateFileCropMutation } from './mutations';

export type { UploadFileRestMutationType } from './mutations';
