import { BaseEntity, Entity as DecribeEntity, Relation, RelationList } from '@lib/entity';
import { i18n } from '@lib/i18n';
import { EmployeeEntityList } from '@modules/employees/entities';
import { TopicEntity } from '@modules/diary/subjects/entities';
import { egAttendanceShortTranslates } from '@modules/diary/schedule/constants';

import type { EmployeeEntity } from '@modules/employees/entities';
import type { EgLessonScoreCollectionSchoolItem } from '@modules/types/graphql';

export type Entity = Partial<EgLessonScoreCollectionSchoolItem> | null;

@DecribeEntity('LessonScoreSchoolItemEntity')
class LessonScoreSchoolItemEntity extends BaseEntity {
    fullName: string;
    company: number;
    platoon: number;
    platoonX: number;
    time: string;
    subject: string;
    subjectColor: string;
    attendance: string;
    score: number | null;

    @RelationList(() => EmployeeEntityList)
    instructors: EmployeeEntity[];

    @Relation(() => TopicEntity)
    topic: TopicEntity;

    constructor(lessonScoreSchoolItem: Entity) {
        super(lessonScoreSchoolItem);

        this.fullName = lessonScoreSchoolItem?.fullName ?? '';
        this.company = lessonScoreSchoolItem?.company ?? 0;
        this.platoon = lessonScoreSchoolItem?.platoon ?? 0;
        this.platoonX = lessonScoreSchoolItem?.platoonX ?? 0;
        this.time = lessonScoreSchoolItem?.time ?? '';
        this.subject = lessonScoreSchoolItem?.subject ?? '';
        this.subjectColor = lessonScoreSchoolItem?.subjectColor ?? '';
        this.attendance = lessonScoreSchoolItem?.attendance ?? '';
        this.score = lessonScoreSchoolItem?.score || null;
    }

    getSubjectLabel() {
        const label = (this.subject + ' ' + this.topic.name).trim();

        return label;
    }

    getInstructorsName() {
        const names = this.instructors.map(instructor => instructor.fullName || '-');

        return names;
    }

    getTranslatedAttendance() {
        if (!this.attendance) {
            return '';
        }

        const result = i18n.t(egAttendanceShortTranslates[this.attendance]);

        return result;
    }
}

export { LessonScoreSchoolItemEntity };
