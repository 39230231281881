import React from 'react';
import { useDebounce } from 'react-use';
import { useLocation } from '@reach/router';

import { CommonHelper } from '@helpers';
import { useLocationQuery } from '@modules/layout/hooks';
import { useEvent } from '@modules/shared/hooks';
import { usePageFiltersDrawer } from './page-filters-drawer-context';

import type { Filters, PageFiltersOptions, PageFiltersResult } from './types';

const useInternalPageFilters = <T extends Filters>(
    options: PageFiltersOptions<T>,
): PageFiltersResult<T> => {
    const {
        disableAutoSetFilters,
        queryFilters,
        initials,
        drawerFiltersKeys = [],
        onExecDefaultFilterCondition,
    } = options;

    const { onClose } = usePageFiltersDrawer();

    const location = useLocation();
    const { setQuery } = useLocationQuery();

    const [filters, setFilters] = React.useState<T>(() =>
        CommonHelper.fillDefaultValues<T>(queryFilters, initials),
    );

    const setFilterValue: PageFiltersResult<T>['setFilterValue'] = useEvent((field, value) =>
        setFilters(state => ({ ...state, [field]: value })),
    );

    const handleChangeField: PageFiltersResult<T>['handleChange'] = useEvent(event => {
        const name = event.target.name;
        const value = event.target.value;

        setFilterValue(name, value);
    });

    const handleSetFilters: PageFiltersResult<T>['setFilters'] = useEvent(resetted => {
        const resultFilters = {
            ...filters,
            ...(resetted ?? {}),
        };

        const updatedQuery = {
            ...queryFilters,
            ...resultFilters,
        };

        if (resetted) {
            setFilters(resultFilters);
        }

        setQuery(updatedQuery);
    });

    const handleSubmitFilters: PageFiltersResult<T>['handleSubmit'] = useEvent(() => {
        handleSetFilters();
        onClose();
    });

    const handleResetFilters: PageFiltersResult<T>['resetFilters'] = useEvent(type => {
        if (type === 'drawer') {
            const resetted = Object.entries(initials).reduce(
                (carry, [key, value]) =>
                    drawerFiltersKeys.includes(key) ? { ...carry, [key]: value } : carry,
                {} as T,
            );

            handleSetFilters(resetted);
            onClose();
        } else if (type === 'toolbar') {
            const resetted = Object.entries(initials).reduce(
                (carry, [key, value]) =>
                    !drawerFiltersKeys.includes(key) ? { ...carry, [key]: value } : carry,
                {} as T,
            );

            handleSetFilters(resetted);
        } else {
            handleSetFilters(initials);
        }
    });

    const toolbarFiltersDeps = React.useMemo(
        () =>
            Object.entries(filters).map(([key, value]) => {
                if (drawerFiltersKeys.length !== 0) {
                    return !drawerFiltersKeys.includes(key) ? value : null;
                }

                return value;
            }),
        [filters, drawerFiltersKeys.toString()],
    );

    useDebounce(
        () => {
            if (disableAutoSetFilters) {
                return;
            }

            handleSetFilters();
        },
        200,
        toolbarFiltersDeps,
    );

    React.useEffect(() => {
        let defaultFilterCondition = location.search === '';

        if (onExecDefaultFilterCondition) {
            defaultFilterCondition =
                defaultFilterCondition || onExecDefaultFilterCondition(location.search);
        }

        if (defaultFilterCondition) {
            setFilters(CommonHelper.fillDefaultValues<T>(queryFilters, initials));
        }
    }, [location]);

    const drawerFiltersCount = Object.entries(queryFilters)
        .map(([key, value]) => (drawerFiltersKeys.includes(key) ? value : null))
        .filter(filter => filter !== null && typeof filter !== 'undefined').length;

    const issetFilters = Object.values(filters).some(filter => !!filter);

    const result = {
        issetFilters,
        drawerFiltersCount,
        filters,
        setFilterValue,
        setFilters: handleSetFilters,
        resetFilters: handleResetFilters,
        handleChange: handleChangeField,
        handleSubmit: handleSubmitFilters,
    };

    return result;
};

export { useInternalPageFilters };
