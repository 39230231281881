import _uniqBy from 'lodash/uniqBy';
import { getTime } from 'date-fns';

import { EntityList } from '@lib/entity';
import { LessonTimeEntity } from './lesson-time-entity';

import type { Entity } from './lesson-time-entity';

class LessonTimeEntityList extends EntityList<LessonTimeEntity> {
    constructor(items: Entity[] | null, count?: number | null) {
        super(LessonTimeEntity, items, count);
    }

    getDaysList() {
        const uniqTimeList = _uniqBy(this.entities, 'date');

        const dateToTime = (date: string) => getTime(new Date(date));

        const daysList = uniqTimeList
            .map(timeList => new LessonTimeEntity({ id: timeList.id, date: timeList.date }))
            .sort((a, b) => (dateToTime(a.date) > dateToTime(b.date) ? 1 : -1));

        return daysList;
    }

    getTimeListByDay(date: string) {
        const timeList = this.entities.filter(entity => entity.date === date);

        return timeList;
    }
}

export { LessonTimeEntityList };
