import { EgAttendance } from '@modules/types/graphql';

export const egAttendanceTranslates = {
    [EgAttendance.attended]: 'diary:schedule.blocks.eg_attendance.attended',
    [EgAttendance.released]: 'diary:schedule.blocks.eg_attendance.released',
    [EgAttendance.absent]: 'diary:schedule.blocks.eg_attendance.absent',
};

export const egAttendanceShortTranslates = {
    [EgAttendance.attended]: 'diary:schedule.blocks.short_eg_attendance.attended',
    [EgAttendance.released]: 'diary:schedule.blocks.short_eg_attendance.released',
    [EgAttendance.absent]: 'diary:schedule.blocks.short_eg_attendance.absent',
};

export const egAttendanceOptions: AutocompleteOption<string>[] = Object.entries(EgAttendance).map(
    ([, value]) => ({
        key: value,
        value: egAttendanceTranslates[value],
    }),
);
