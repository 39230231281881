import { sample } from 'effector';

import { setScheduleTypeAction, resetScheduleTypeAction } from '@modules/diary/schedule/models';
import { $scheduleMoveLessonStore, pickScheduleMoveLesson, setScheduleMoveLesson } from './';

$scheduleMoveLessonStore
    .on(pickScheduleMoveLesson, (state, payload) => ({
        ...state,
        from: payload,
    }))
    .on(setScheduleMoveLesson, (state, payload) => ({
        ...state,
        to: payload,
    }))
    .reset(resetScheduleTypeAction);

sample({
    source: pickScheduleMoveLesson,
    fn: () => 'single:move',
    target: setScheduleTypeAction,
});
