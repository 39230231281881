import { sample } from 'effector';

import { setScheduleTypeAction, resetScheduleTypeAction } from '@modules/diary/schedule/models';
import { $scheduleCopyLessonStore, setScheduleCopyLesson } from './';

$scheduleCopyLessonStore
    .on(setScheduleCopyLesson, (_, payload) => payload)
    .reset(resetScheduleTypeAction);

sample({
    source: setScheduleCopyLesson,
    fn: () => 'bulk:copy',
    target: setScheduleTypeAction,
});
