import { format } from 'date-fns';

import { BaseEntity, Entity as DecribeEntity, Relation, RelationList } from '@lib/entity';
import { i18n } from '@lib/i18n';
import { FileInfoEntity, TranslationEntityList } from '@modules/shared/entities';
import { UserEntity, UserEntityType } from '@modules/user/entities';
import { roleTranslates } from '@modules/employees/constants';

import type { TranslationEntity } from '@modules/shared/entities';
import type { EmployeeRole, Employee } from '@modules/types/graphql';

export type Entity =
    | (Partial<Omit<Employee, 'attachedUser'>> & {
          attachedUser?: UserEntityType;
          qrCodeUrl?: string | null;
      })
    | null;

@DecribeEntity('EmployeeEntity')
class EmployeeEntity extends BaseEntity {
    id: string;
    fullName: string;
    specialization: string;
    role: EmployeeRole | string;
    birthdate: any;
    biography: string;
    address: string;
    phone: string;
    email: string;
    accessSentAt: string;
    isAccessSent: boolean;
    isArchived: boolean;
    qrCodeUrl: string;
    accessSentStatus: string;
    accessSentStatusText: string;
    photoId: string;
    displayInStand: boolean;
    isBlocked: boolean;

    @RelationList(() => TranslationEntityList)
    specializationTranslations: TranslationEntity[];

    @RelationList(() => TranslationEntityList)
    biographyTranslations: TranslationEntity[];

    @Relation(() => UserEntity)
    attachedUser: UserEntity;

    @Relation(() => FileInfoEntity)
    photo: FileInfoEntity;

    constructor(employee: Entity) {
        super(employee);

        this.id = employee?.id ?? '';
        this.fullName = employee?.fullName ?? '';
        this.specialization = employee?.specialization ?? '';
        this.role = employee?.role ?? '';
        this.birthdate = employee?.birthdate ? new Date(employee.birthdate) : null;
        this.biography = employee?.biography ?? '';
        this.phone = employee?.phone ?? '';
        this.email = employee?.email ?? '';
        this.accessSentAt = employee?.accessSentAt ?? '';
        this.isAccessSent = employee?.isAccessSent ?? false;
        this.isArchived = employee?.isArchived ?? false;
        this.qrCodeUrl = employee?.qrCodeUrl ?? '';
        this.accessSentStatus = employee?.accessSentStatus ?? '';
        this.accessSentStatusText = employee?.accessSentStatusText ?? '';
        this.photoId = employee?.photoId ?? '';
        this.displayInStand = employee?.displayInStand ?? false;
        this.isBlocked = employee?.isBlocked ?? false;
    }

    exists() {
        const exists = this.id !== '';

        return exists;
    }

    getEntityIfExists(): EmployeeEntity | null {
        if (this.exists()) {
            return this;
        }

        return null;
    }

    getTranslateRole() {
        if (!this.role) {
            return '-';
        }

        return i18n.t(roleTranslates[this.role]);
    }

    getFormattedAccessSentDate() {
        if (this.accessSentAt === null) {
            return '-';
        }

        try {
            const validDate = new Date(this.accessSentAt);
            const date = format(validDate, 'dd.MM.yyyy');
            const time = format(validDate, 'HH:mm:ss');

            const formatted = i18n.t('common:blocks.time.date_to_minutes', { date, time });

            return formatted;
        } catch {
            return '-';
        }
    }

    getFormattedBirthdate() {
        if (!this.birthdate) {
            return '-';
        }

        try {
            const date = format(new Date(this.birthdate), 'dd.MM.yyy');

            return date;
        } catch {
            return '-';
        }
    }
}

export { EmployeeEntity };
