import { useQuery } from '@apollo/client';

import { useEntity } from '@lib/entity';
import { LocationEntityList } from '@modules/shared/entities';
import { GetLocationsQuery } from '@modules/shared/graphql';

import type { QueryHookOptions } from '@apollo/client';
import type { GetLocationsQueryType, GetLocationsQueryVariables } from '@modules/types/graphql';

const useLocations = (
    options: QueryHookOptions<GetLocationsQueryType, GetLocationsQueryVariables> = {},
) => {
    const queryResult = useQuery<GetLocationsQueryType, GetLocationsQueryVariables>(
        GetLocationsQuery,
        options,
    );

    const { entities: locations, count } = useEntity(
        () => new LocationEntityList(queryResult.data?.getLocations ?? null),
        [queryResult.data],
    );

    const result = {
        locations,
        count,
        ...queryResult,
    };

    return result;
};

export { useLocations };
