import { EntityList } from '@lib/entity';
import { TranslationEntity } from './translation.entity';

import type { Entity } from './translation.entity';

class TranslationEntityList extends EntityList<TranslationEntity> {
    constructor(items: Entity[] | null, count?: number | null) {
        super(TranslationEntity, items, count);
    }
}

export { TranslationEntityList };
