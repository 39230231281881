import { useTranslation } from 'react-i18next';
import { Modal } from '@avangard/ui/core';

import { ChangePasswordForm } from '@modules/auth/organisms';

import type { ModalProps } from '@avangard/ui/core';

type ChangePasswordFormModalProps = Omit<ModalProps, 'title' | 'children'>;

const ChangePasswordFormModal = (props: ChangePasswordFormModalProps) => {
    const { onClose, ...otherProps } = props;

    const { t } = useTranslation(['common']);

    return (
        <Modal title={t('actions.change_password') ?? ''} onClose={onClose} {...otherProps}>
            <ChangePasswordForm onCancel={onClose} />
        </Modal>
    );
};

export { ChangePasswordFormModal };
