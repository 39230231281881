export type ScheduleSettings = {
    includeStatistics?: boolean;
};

const initialScheduleSettings: ScheduleSettings = {
    includeStatistics: false,
};

const scheduleSettingsKey = 'schedule:settings';

export { scheduleSettingsKey, initialScheduleSettings };
