import { EntityList } from '@lib/entity';
import { LessonsTableRowEntity } from './lessons-table-row-entity';

import type { Entity } from './lessons-table-row-entity';

class LessonsTableRowEntityList extends EntityList<LessonsTableRowEntity> {
    constructor(items: Entity[] | null, count?: number | null) {
        super(LessonsTableRowEntity, items, count);
    }
}

export { LessonsTableRowEntityList };
