import { BaseEntity, Entity as DecribeEntity } from '@lib/entity';

import type { TranslationLocale } from '@modules/shared/organisms';
import type { Translation } from '@modules/types/graphql';

export type Entity = Translation | null;

@DecribeEntity('TranslationEntity')
class TranslationEntity extends BaseEntity {
    value: string;
    locale: TranslationLocale;

    constructor(entity: Entity) {
        super(entity);

        this.value = entity?.value ?? '';
        this.locale = (entity?.locale ?? 'ru') as TranslationLocale;
    }
}

export { TranslationEntity };
