import { EntityList } from '@lib/entity';
import { LessonScoreSchoolItemEntity } from './lesson-score-school-item-entity';

import type { Entity } from './lesson-score-school-item-entity';

class LessonScoreSchoolItemEntityList extends EntityList<LessonScoreSchoolItemEntity> {
    constructor(items: Entity[] | null, count?: number | null) {
        super(LessonScoreSchoolItemEntity, items, count);
    }
}

export { LessonScoreSchoolItemEntityList };
