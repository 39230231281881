import { BaseEntity, Entity as DecribeEntity, Relation } from '@lib/entity';
import { i18n } from '@lib/i18n';
import { schoolTypeTranslates } from '@modules/schools/constants';
import { egAttendanceShortTranslates } from '@modules/diary/schedule/constants';
import { StudentMiniInfoEntity } from '@modules/students/entities';

import type { EgLessonScoreCollectionItem } from '@modules/types/graphql';

export type Entity = Partial<EgLessonScoreCollectionItem> | null;

@DecribeEntity('LessonScoreItemEntity')
class LessonScoreItemEntity extends BaseEntity {
    locationName: string;
    schoolType: string;
    schoolName: string;
    score: number | null;
    attendance: string;

    @Relation(() => StudentMiniInfoEntity)
    student: StudentMiniInfoEntity;

    constructor(lessonScoreItem: Entity) {
        super(lessonScoreItem);

        this.locationName = lessonScoreItem?.locationName ?? '';
        this.schoolType = lessonScoreItem?.schoolType ?? '';
        this.schoolName = lessonScoreItem?.schoolName ?? '';
        this.score = lessonScoreItem?.score || null;
        this.attendance = lessonScoreItem?.attendance || '';
    }

    getTranslatedSchoolType() {
        if (!this.schoolType) {
            return '';
        }

        const result = i18n.t(schoolTypeTranslates[this.schoolType]);

        return result;
    }

    getTranslatedAttendance() {
        if (!this.attendance) {
            return '';
        }

        const result = i18n.t(egAttendanceShortTranslates[this.attendance]);

        return result;
    }
}

export { LessonScoreItemEntity };
