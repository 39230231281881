import { format, endOfDay } from 'date-fns';

import type { CourseEntity } from '@modules/courses/entities';
import type { Platform, LapCreateArgs, LapProgramType } from '@modules/types/graphql';

export type CreateLapFormValues = {
    programType: LapProgramType | '';
    from: Date | null;
    to: Date | null;
    platform: Platform | '';
    course: CourseEntity | null;
    companyCount: string;
    platoonCount: string;
};

class CreateLapRequest {
    programType: LapCreateArgs['programType'];
    from: LapCreateArgs['from'];
    to: LapCreateArgs['to'];
    platform: LapCreateArgs['platform'];
    courseId: LapCreateArgs['courseId'];
    companyCount: LapCreateArgs['companyCount'];
    platoonCount: LapCreateArgs['platoonCount'];

    constructor(values: CreateLapFormValues) {
        this.programType = (values.programType as LapProgramType) || undefined;
        this.from = values.from ? format(endOfDay(values.from), 'yyyy-MM-dd') : undefined;
        this.to = values.to ? format(endOfDay(values.to), 'yyyy-MM-dd') : undefined;
        this.platform = (values.platform as Platform) || undefined;
        this.courseId = values.course?.id;
        this.companyCount = values.companyCount ? parseInt(values.companyCount, 10) : 0;
        this.platoonCount = values.platoonCount ? parseInt(values.platoonCount, 10) : 0;
    }
}

export { CreateLapRequest };
