import { createStore, createEvent } from 'effector';
import { useStore } from 'effector-react';

import { LessonsTableColumnEntity } from '@modules/diary/schedule/entities';

export type DailyScheduleCopyLesson = LessonsTableColumnEntity;

type DailyScheduleCopyLessonStore = DailyScheduleCopyLesson;

// prettier-ignore
const setDailyScheduleCopyLesson = createEvent<DailyScheduleCopyLessonStore>('set create lesson values');
const $dailyScheduleCopyLessonStore = createStore<DailyScheduleCopyLessonStore>(
    new LessonsTableColumnEntity({}),
);

const useDailyScheduleCopyLesson = (): DailyScheduleCopyLessonStore =>
    useStore($dailyScheduleCopyLessonStore);

export { $dailyScheduleCopyLessonStore, useDailyScheduleCopyLesson, setDailyScheduleCopyLesson };
