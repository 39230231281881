import { EntityList } from '@lib/entity';
import { TopicEntity } from './topic-entity';

import type { Entity } from './topic-entity';

class TopicEntityList extends EntityList<TopicEntity> {
    constructor(items: Entity[] | null, count?: number | null) {
        super(TopicEntity, items, count);
    }
}

export { TopicEntityList };
