import React from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import { TextField, Button } from '@avangard/ui/core';

import { FormStyled } from '@modules/layout/styled';
import { useEnqueueStacks } from '@modules/layout/hooks';
import { ExtendedFormik, Form } from '@modules/layout/organisms';
import { ChangePasswordMutation } from '@modules/auth/graphql';

import type {
    ChangePasswordMutationType,
    ChangePasswordMutationVariables,
} from '@modules/types/graphql';

type ChangePasswordFormProps = {
    onCancel: () => void;
};

const StyledForm = styled(Form)`
    width: 100%;

    > * {
        &:not(:last-of-type) {
            margin-bottom: 24px;
        }
    }
`;

const FormRowSubmit = styled(FormStyled.FormRowSubmit)`
    justify-content: flex-end;
`;

const ChangePasswordForm = (props: ChangePasswordFormProps): React.ReactElement => {
    const { onCancel } = props;

    const { t } = useTranslation(['common', 'errors', 'notifiers', 'auth']);

    const { enqueueSuccess } = useEnqueueStacks();

    const [changePassword, { loading: changePasswordLoading }] = useMutation<
        ChangePasswordMutationType,
        ChangePasswordMutationVariables
    >(ChangePasswordMutation);

    return (
        <ExtendedFormik
            validationSchema={yup.object({
                oldPassword: yup.string().required(t('errors:validations.required') ?? ''),
                newPassword: yup
                    .string()
                    .required(t('errors:validations.required') ?? '')
                    .notOneOf(
                        [yup.ref('oldPassword'), null],
                        t('errors:validations.password_unique') ?? '',
                    )
                    .min(8, t('errors:validations.password_min') ?? ''),
                confirmNewPassword: yup
                    .string()
                    .required(t('errors:validations.required') ?? '')
                    .oneOf(
                        [yup.ref('newPassword'), null],
                        t('errors:validations.password_equal') ?? '',
                    ),
            })}
            initialValues={{
                oldPassword: '',
                newPassword: '',
                confirmNewPassword: '',
            }}
            onSubmit={async values => {
                try {
                    await changePassword({
                        variables: {
                            old: values.oldPassword,
                            new: values.newPassword,
                        },
                    });

                    enqueueSuccess(t('notifiers:password.success'));
                    onCancel();
                } catch (e) {
                    console.log(e);
                    throw e;
                }
            }}
        >
            {formikProps => {
                const { values, errors, handleChange, handleBlur, isSubmitting } = formikProps;

                return (
                    <StyledForm>
                        <TextField
                            required
                            fullWidth
                            type='password'
                            id='oldPassword'
                            label={t('auth:forms.labels.old_password') ?? ''}
                            placeholder={t('auth:forms.placeholder.password_example') ?? ''}
                            value={values.oldPassword}
                            error={!!errors.oldPassword}
                            helperText={errors.oldPassword}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />

                        <TextField
                            required
                            fullWidth
                            type='password'
                            id='newPassword'
                            label={t('auth:forms.labels.new_password') ?? ''}
                            placeholder={t('auth:forms.placeholder.password_example') ?? ''}
                            value={values.newPassword}
                            error={!!errors.newPassword}
                            helperText={errors.newPassword}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />

                        <TextField
                            required
                            fullWidth
                            type='password'
                            id='confirmNewPassword'
                            label={t('auth:forms.labels.repeat_password') ?? ''}
                            placeholder={t('auth:forms.placeholder.password_example') ?? ''}
                            value={values.confirmNewPassword}
                            error={!!errors.confirmNewPassword}
                            helperText={errors.confirmNewPassword}
                            onChange={handleChange}
                            onBlur={handleBlur}
                        />

                        <FormRowSubmit>
                            <Button
                                variant='outlined'
                                disabled={isSubmitting || changePasswordLoading}
                                onClick={onCancel}
                            >
                                {t('common:actions.cancel')}
                            </Button>

                            <Button type='submit' loading={isSubmitting || changePasswordLoading}>
                                {t('common:actions.save')}
                            </Button>
                        </FormRowSubmit>
                    </StyledForm>
                );
            }}
        </ExtendedFormik>
    );
};

export { ChangePasswordForm };
