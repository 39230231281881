export enum Score {
    score_1 = '1',
    score_2 = '2',
    score_3 = '3',
    score_4 = '4',
    score_5 = '5',
}

export const scoresTranslates = {
    [Score.score_1]: '1',
    [Score.score_2]: '2',
    [Score.score_3]: '3',
    [Score.score_4]: '4',
    [Score.score_5]: '5',
};

export const scoresOptions: AutocompleteOption<number>[] = Object.entries(Score).map(
    ([, value]) => ({
        key: Number(value),
        value: scoresTranslates[value],
    }),
);

export const scoresSelectOptions = Object.entries(scoresTranslates)
    .reverse()
    .map(([key, value]) => ({ key, value }));
