import styled from 'styled-components';
import ReactCountryFlag from 'react-country-flag';
import { useApolloClient } from '@apollo/client';
import { makeStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { MenuItem } from '@material-ui/core';
import { TextField } from '@avangard/ui/core';

import { useNavigate } from '@lib/routing';

const useSelectStyles = makeStyles({
    select: {
        color: '#fff',
    },
    icon: {
        color: '#fff',
    },
});

const useInputStyles = makeStyles({
    notchedOutline: {
        borderColor: '#fff',
    },
});

const Language = styled.span`
    display: flex;
    flex-wrap: wrap;
    margin-inline-end: 8px;
    line-height: 1;
`;

const LANGUAGE_FLAGS_MAP = {
    ru: <ReactCountryFlag countryCode='RU' style={{ fontSize: 16 }} />,
    en: <ReactCountryFlag countryCode='US' style={{ fontSize: 16 }} />,
    ar: <ReactCountryFlag countryCode='AE' style={{ fontSize: 16 }} />,
};

const ChangeLanguage = (): React.ReactElement => {
    const { i18n } = useTranslation();

    const apolloClient = useApolloClient();

    const navigate = useNavigate();

    const selectClasses = useSelectStyles();
    const inputClasses = useInputStyles();

    const handleChangeLanguage = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const language = event.target.value;

        const regex = new RegExp(`/${i18n.language}/`);
        const routeTo =
            window.location.pathname.replace(regex, `/${language}/`) + window.location.search;

        await i18n.changeLanguage(language);
        apolloClient.reFetchObservableQueries(true);

        navigate(routeTo);
    };

    const renderLanguage = (language: string) => {
        if (language === 'ar') {
            return 'uae';
        }

        return language;
    };

    return (
        <TextField
            select
            name='language'
            value={i18n.language}
            onChange={handleChangeLanguage}
            SelectProps={{ classes: selectClasses }}
            InputProps={{ classes: inputClasses }}
        >
            {Object.keys(LANGUAGE_FLAGS_MAP).map(language => (
                <MenuItem key={language} value={language}>
                    <Language>{LANGUAGE_FLAGS_MAP[language]}</Language>

                    {renderLanguage(language).toUpperCase()}
                </MenuItem>
            ))}
        </TextField>
    );
};

export { ChangeLanguage };
