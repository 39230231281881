import { createStore, createEvent } from 'effector';
import { useStore } from 'effector-react';

import { LessonsTableColumnEntity } from '@modules/diary/schedule/entities';

export type ScheduleCopyLesson = LessonsTableColumnEntity;

type ScheduleCopyLessonStore = ScheduleCopyLesson;

// prettier-ignore
const setScheduleCopyLesson = createEvent<ScheduleCopyLessonStore>('set create lesson values');
const $scheduleCopyLessonStore = createStore<ScheduleCopyLessonStore>(
    new LessonsTableColumnEntity({}),
);

const useScheduleCopyLesson = (): ScheduleCopyLessonStore => useStore($scheduleCopyLessonStore);

export { $scheduleCopyLessonStore, useScheduleCopyLesson, setScheduleCopyLesson };
