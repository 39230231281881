import { createStore, createEvent } from 'effector';
import { useStore } from 'effector-react';

export type ScheduleBulkLesson = {
    timeId: string;
    company: number;
    platoon: number;
    id?: string;
};

type ScheduleBulkLessonsStore = {
    bulkLessons: (ScheduleBulkLesson | null)[];
    hasBulkLessons: boolean;
};

// prettier-ignore
const setScheduleBulkLesson = createEvent<ScheduleBulkLesson>('add bulk lesson (if exists), remove if not exists');
const setScheduleBulkLessons = createEvent<ScheduleBulkLesson[]>('add list of lessons');
const resetScheduleBulkLessons = createEvent('remove all lessons');
const $scheduleBulkLessonsStore = createStore<ScheduleBulkLessonsStore>({
    bulkLessons: [],
    hasBulkLessons: false,
});

const useScheduleBulkLessons = (): ScheduleBulkLessonsStore => useStore($scheduleBulkLessonsStore);

export {
    $scheduleBulkLessonsStore,
    useScheduleBulkLessons,
    setScheduleBulkLesson,
    setScheduleBulkLessons,
    resetScheduleBulkLessons,
};
