import styled from 'styled-components';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';
import {
    Button,
    DatepickerSinglePicker,
    DatepickerTextField,
    OnDateChangeProps,
    TextField,
} from '@avangard/ui/core';
import { MenuItem } from '@material-ui/core';

import { useEnqueueStacks } from '@modules/layout/hooks';
import { FormStyled } from '@modules/layout/styled';
import { ExtendedFormik, Form } from '@modules/layout/organisms';
import { useCurrentUser } from '@modules/user/hooks';
import { programTypes } from '@modules/lap/constants';
import { useLapsTableFiltersQuery } from '@modules/lap/hooks';
import { CreateLapRequest, LapCollectionRequest } from '@modules/lap/requests';
import { CreateLapMutation, GetLapCollectionQuery } from '@modules/lap/graphql';
import { UserGrantActionToken, UserGrantCategoryToken } from '@modules/types/graphql';

import type { CreateLapFormValues } from '@modules/lap/requests';
import type { CreateLapMutationType, CreateLapMutationVariables } from '@modules/types/graphql';

type LapCreateFormProps = {
    onCancel: () => void;
};

const StyledForm = styled(Form)`
    width: 100%;

    > * {
        &:not(:last-of-type) {
            margin-bottom: 24px;
        }
    }
`;

const FormRowSubmit = styled(FormStyled.FormRowSubmit)`
    justify-content: flex-end;
`;

const LapCreateForm = (props: LapCreateFormProps) => {
    const { onCancel } = props;

    const { t } = useTranslation(['common', 'errors', 'lap']);

    const { enqueueSuccess, enqueueError } = useEnqueueStacks();

    const { filters } = useLapsTableFiltersQuery();
    const args = new LapCollectionRequest(filters);

    const { currentUser } = useCurrentUser();
    const { lapGrants } = currentUser.can({
        [UserGrantCategoryToken.lap]: [UserGrantActionToken.list],
    });

    const [createLap] = useMutation<CreateLapMutationType, CreateLapMutationVariables>(
        CreateLapMutation,
    );

    const initialValues: CreateLapFormValues = {
        programType: '',
        from: null,
        to: null,
        platform: '',
        course: null,
        companyCount: '',
        platoonCount: '',
    };

    return (
        <ExtendedFormik
            validationSchema={yup.object({
                programType: yup.string().required(t('errors:validations.required') ?? ''),
                from: yup
                    .date()
                    .nullable()
                    .required(t('errors:validations.required') ?? ''),
                to: yup
                    .date()
                    .nullable()
                    .required(t('errors:validations.required') ?? '')
                    .min(yup.ref('from'), t('errors:time.invalid_range') ?? ''),
                companyCount: yup
                    .number()
                    .nullable()
                    .required(t('errors:validations.required') ?? ''),
                platoonCount: yup
                    .number()
                    .nullable()
                    .required(t('errors:validations.required') ?? ''),
            })}
            initialValues={initialValues}
            onSubmit={async values => {
                try {
                    let options: Parameters<typeof createLap>[0] = {
                        variables: { item: new CreateLapRequest(values) },
                    };

                    if (lapGrants.canList) {
                        options = {
                            ...options,
                            refetchQueries: [{ query: GetLapCollectionQuery, variables: { args } }],
                            awaitRefetchQueries: true,
                        };
                    }

                    const { data: createLapData } = await createLap(options);

                    const lapId = createLapData?.createLap?.id;

                    if (lapId) {
                        enqueueSuccess(t('lap:notifiers.add_lap.success'));
                        onCancel();
                    } else {
                        enqueueError(t('lap:notifiers.add_lap.error'));
                    }
                } catch (e) {
                    console.log(e);
                    throw e;
                }
            }}
        >
            {formikProps => {
                const { values, errors, isSubmitting, setFieldValue, handleChange } = formikProps;

                const handleChangeDate = (field: string) => (data: OnDateChangeProps) =>
                    setFieldValue(field, data.date);

                return (
                    <StyledForm>
                        <TextField
                            select
                            fullWidth
                            id='programType'
                            name='programType'
                            label={t('common:forms.labels.program_type')}
                            placeholder={t('common:forms.placeholder.choose_program_type') ?? ''}
                            value={values.programType}
                            error={!!errors.programType}
                            helperText={errors.programType}
                            onChange={handleChange}
                        >
                            <MenuItem disabled value=''>
                                {t('common:forms.placeholder.choose_program_type')}
                            </MenuItem>

                            {programTypes.map(programType => (
                                <MenuItem key={programType.key} value={programType.key}>
                                    {t(programType.value)}
                                </MenuItem>
                            ))}
                        </TextField>

                        <DatepickerSinglePicker
                            date={values.from}
                            onDateChange={handleChangeDate('from')}
                        >
                            {datepickerProps => {
                                const { inputProps, inputComponent } = datepickerProps;

                                return (
                                    <DatepickerTextField
                                        id='from'
                                        label={t('common:forms.labels.start_lap_date')}
                                        placeholder={t('common:forms.placeholder.date') ?? ''}
                                        error={!!errors.from}
                                        helperText={errors.from}
                                        InputProps={{ inputComponent }}
                                        {...inputProps}
                                    />
                                );
                            }}
                        </DatepickerSinglePicker>

                        <DatepickerSinglePicker
                            date={values.to}
                            onDateChange={handleChangeDate('to')}
                        >
                            {datepickerProps => {
                                const { inputProps, inputComponent } = datepickerProps;

                                return (
                                    <DatepickerTextField
                                        id='to'
                                        label={t('common:forms.labels.end_lap_date')}
                                        placeholder={t('common:forms.placeholder.date') ?? ''}
                                        error={!!errors.to}
                                        helperText={errors.to}
                                        InputProps={{ inputComponent }}
                                        {...inputProps}
                                    />
                                );
                            }}
                        </DatepickerSinglePicker>

                        <TextField
                            fullWidth
                            type='number'
                            id='companyCount'
                            name='companyCount'
                            label={t('lap:form.labels.company_count')}
                            placeholder={t('lap:form.placeholder.typing_company_count') ?? ''}
                            value={values.companyCount}
                            error={!!errors.companyCount}
                            inputProps={{ min: 1, max: 12 }}
                            helperText={errors.companyCount}
                            onChange={handleChange}
                        />

                        <TextField
                            fullWidth
                            type='number'
                            id='platoonCount'
                            name='platoonCount'
                            label={t('lap:form.labels.platoon_count_in_company')}
                            placeholder={
                                t('lap:form.placeholder.typing_platoon_count_in_company') ?? ''
                            }
                            value={values.platoonCount}
                            error={!!errors.platoonCount}
                            inputProps={{ min: 1, max: 8 }}
                            helperText={errors.platoonCount}
                            onChange={handleChange}
                        />

                        <FormRowSubmit>
                            <Button variant='outlined' disabled={isSubmitting} onClick={onCancel}>
                                {t('common:actions.cancel')}
                            </Button>

                            <Button type='submit' loading={isSubmitting}>
                                {t('common:actions.add')}
                            </Button>
                        </FormRowSubmit>
                    </StyledForm>
                );
            }}
        </ExtendedFormik>
    );
};

export { LapCreateForm };
