import { sample } from 'effector';

import {
    setDailyScheduleTypeAction,
    resetDailyScheduleTypeAction,
} from '@modules/daily-schedule/models';
import { $dailyScheduleCopyLessonStore, setDailyScheduleCopyLesson } from './';

$dailyScheduleCopyLessonStore
    .on(setDailyScheduleCopyLesson, (_, payload) => payload)
    .reset(resetDailyScheduleTypeAction);

sample({
    source: setDailyScheduleCopyLesson,
    fn: () => 'bulk:copy',
    target: setDailyScheduleTypeAction,
});
