import { EntityList } from '@lib/entity';
import { EgLessonPlaceEntity } from './eg-lesson-place-entity';

import type { Entity } from './eg-lesson-place-entity';

class EgLessonPlaceEntityList extends EntityList<EgLessonPlaceEntity> {
    constructor(items: Entity[] | null, count?: number | null) {
        super(EgLessonPlaceEntity, items, count);
    }
}

export { EgLessonPlaceEntityList };
