import { UserRole } from '@modules/types/graphql';

import type { Classifiers } from './typings';

const permissions: Record<UserRole, Partial<Classifiers>> = {
    [UserRole.superAdmin]: {
        laps: ['all'],
        dashboard: ['all'],
        students: ['all'],
        employees: ['all'],
        schools: ['all'],
        dailySchedule: ['all'],
        diarySchedule: ['all'],
        diarySubjects: ['all'],
        diaryModules: ['all'],
        diaryLessonPlaces: ['all'],
        evaluationPlatoons: ['all'],
        evaluationStudents: ['all'],
        evaluationCriteriaPlatoons: ['all'],
        evaluationCriteriaStudents: ['all'],
        evaluationResponsibles: ['all'],
        ratings: ['all'],
        wikiCategories: ['all'],
        wikiArticles: ['all'],
        statsLessons: ['all'],
        statsStudents: ['all'],
        olMain: ['all'],
        olSubjects: ['all'],
        olLectures: ['all'],
        olVideoMaterials: ['all'],
        olTesting: ['all'],
        events: ['all'],
    },
    [UserRole.admin]: {
        laps: ['all'],
        dashboard: ['all'],
        students: ['all'],
        employees: ['all'],
        schools: ['all'],
        dailySchedule: ['all'],
        diarySchedule: ['all'],
        diarySubjects: ['all'],
        diaryModules: ['all'],
        diaryLessonPlaces: ['all'],
        evaluationPlatoons: ['all'],
        evaluationStudents: ['all'],
        evaluationCriteriaPlatoons: ['all'],
        evaluationCriteriaStudents: ['all'],
        evaluationResponsibles: ['all'],
        ratings: ['all'],
        wikiCategories: ['all'],
        wikiArticles: ['all'],
        statsLessons: ['all'],
        statsStudents: ['all'],
        olMain: ['all'],
        olSubjects: ['all'],
        olLectures: ['all'],
        olVideoMaterials: ['all'],
        olTesting: ['all'],
        events: ['all'],
    },
    [UserRole.student]: {
        laps: ['change'],
        diarySchedule: ['list', 'pick-date'],
    },
    [UserRole.school]: {
        laps: ['change'],
        students: [
            'list',
            'view',
            'search',
            'filter',
            'create',
            'edit',
            'view-access',
            'send-access',
        ],
        diarySchedule: ['list', 'pick-date'],
    },
    [UserRole.methodist]: {
        laps: ['list', 'change'],
        dashboard: ['all'],
        students: [
            'list',
            'view',
            'search',
            'filter',
            'edit',
            'import',
            'export',
            'download-template',
        ],
        employees: ['list', 'view', 'search', 'filter', 'create', 'edit'],
        schools: ['list', 'search', 'filter'],
        dailySchedule: ['all'],
        diarySchedule: [
            'list',
            'view',
            'pick-date',
            'actions',
            'filter',
            'change-view',
            'create-time',
            'create-bulk-lessons',
            'remove-bulk-lessons',
            'copy-lessons',
            'export-timesheet',
            'export-schools',
            'set-settings',
            'create-lesson',
            'edit-lesson',
            'move-lesson',
            'copy-lesson',
            'remove-lesson',
            'edit-time',
            'delete-time',
            'set-discipline-score',
            'set-lesson-score',
            'bulk-set-lesson-score',
            'set-all-lesson-score',
        ],
        diarySubjects: ['list', 'view', 'create', 'edit', 'delete'],
        diaryLessonPlaces: ['list', 'view', 'create', 'edit', 'delete'],
        diaryModules: ['list', 'view', 'create', 'edit', 'delete'],
        evaluationPlatoons: ['all'],
        evaluationStudents: ['all'],
        evaluationCriteriaPlatoons: ['all'],
        evaluationCriteriaStudents: ['all'],
        evaluationResponsibles: ['all'],
        ratings: ['list'],
        wikiCategories: ['all'],
        wikiArticles: ['all'],
        statsLessons: ['list', 'search', 'filter'],
        statsStudents: ['list', 'search', 'filter'],
        olMain: ['list', 'view'],
        olSubjects: ['list', 'view'],
        olLectures: ['list', 'view'],
        olVideoMaterials: ['list', 'view'],
        olTesting: ['view', 'process'],
    },
    [UserRole.registrar]: {
        laps: ['all'],
        dashboard: ['all'],
        students: [
            'list',
            'view',
            'search',
            'filter',
            'create',
            'edit',
            'delete',
            'edit-access',
            'view-access',
            'send-access',
            'import',
            'export',
            'download-template',
            'distribute',
        ],
        employees: ['list', 'search', 'filter'],
        schools: ['list', 'view', 'filter', 'search', 'create', 'edit', 'edit-access', 'export'],
        dailySchedule: ['grid', 'timeline', 'pick-date', 'actions', 'change-view'],
        diarySchedule: ['list', 'pick-date', 'actions', 'filter', 'change-view', 'export-schools'],
        diarySubjects: ['list'],
        diaryModules: ['list'],
        diaryLessonPlaces: ['list'],
        evaluationPlatoons: ['list', 'view', 'pick-date'],
        evaluationStudents: ['list', 'view'],
        ratings: ['list'],
        wikiCategories: ['list'],
        wikiArticles: ['list'],
        statsLessons: ['list', 'search', 'filter'],
        statsStudents: ['list', 'search', 'filter'],
        olMain: ['list', 'view'],
        olSubjects: ['list', 'view'],
        olLectures: ['list', 'view'],
        olVideoMaterials: ['list', 'view'],
        olTesting: ['view', 'process'],
    },
    [UserRole.instructor]: {
        laps: ['change'],
        dashboard: ['all'],
        students: ['list', 'search', 'filter', 'export'],
        employees: ['list', 'search', 'filter'],
        schools: ['list', 'filter', 'search'],
        dailySchedule: ['grid', 'timeline', 'pick-date', 'actions', 'change-view'],
        diarySchedule: [
            'list',
            'view',
            'pick-date',
            'actions',
            'filter',
            'change-view',
            'set-only-my-lessons',
            'set-discipline-score',
            'set-lesson-score',
            'bulk-set-lesson-score',
            'set-all-lesson-score',
        ],
        evaluationPlatoons: ['list', 'view', 'pick-date'],
        evaluationStudents: ['list', 'view'],
        ratings: ['list'],
        wikiCategories: ['all'],
        wikiArticles: ['all'],
        statsLessons: ['list', 'search', 'filter'],
        statsStudents: ['list', 'search', 'filter'],
        olMain: ['list', 'view'],
        olSubjects: ['list', 'view'],
        olLectures: ['list', 'view'],
        olVideoMaterials: ['list', 'view'],
        olTesting: ['view', 'process'],
    },
    [UserRole.mentor]: {
        laps: ['change'],
        dashboard: ['all'],
        students: ['list', 'view', 'search', 'filter', 'edit', 'view-access', 'send-access'],
        employees: ['list', 'search', 'filter'],
        schools: ['list', 'filter', 'search'],
        dailySchedule: [
            'grid',
            'timeline',
            'pick-date',
            'actions',
            'change-view',
            'create-lesson',
            'edit-lesson',
            'copy-lesson',
            'remove-lesson',
        ],
        diarySchedule: [
            'list',
            'view',
            'pick-date',
            'actions',
            'filter',
            'change-view',
            'set-only-my-lessons',
            'set-discipline-score',
            'set-lesson-score',
            'bulk-set-lesson-score',
            'set-all-lesson-score',
        ],
        evaluationPlatoons: ['list', 'view', 'pick-date', 'set', 'edit', 'delete'],
        evaluationStudents: ['list', 'view', 'set', 'edit', 'delete'],
        ratings: ['list'],
        wikiCategories: ['list'],
        wikiArticles: ['list'],
        statsLessons: ['list', 'search', 'filter'],
        statsStudents: ['list', 'search', 'filter'],
        olMain: ['list', 'view'],
        olSubjects: ['list', 'view'],
        olLectures: ['list', 'view'],
        olVideoMaterials: ['list', 'view'],
        olTesting: ['view', 'process'],
    },
    [UserRole.leader]: {
        laps: ['change'],
        dashboard: ['all'],
        students: ['list', 'search', 'filter', 'edit', 'export'],
        employees: ['list', 'search', 'filter', 'create', 'edit'],
        schools: ['list', 'search', 'filter'],
        dailySchedule: [
            'grid',
            'timeline',
            'pick-date',
            'actions',
            'change-view',
            'create-lesson',
            'edit-lesson',
            'copy-lesson',
            'remove-lesson',
        ],
        diarySchedule: [
            'list',
            'view',
            'pick-date',
            'actions',
            'filter',
            'change-view',
            'set-only-my-lessons',
            'set-discipline-score',
            'set-lesson-score',
            'bulk-set-lesson-score',
            'set-all-lesson-score',
        ],
        evaluationPlatoons: ['list', 'view', 'pick-date', 'set', 'edit', 'delete'],
        evaluationStudents: ['list', 'view'],
        evaluationResponsibles: ['list', 'edit', 'bulk-edit'],
        ratings: ['list'],
        wikiCategories: ['list'],
        wikiArticles: ['list'],
        statsLessons: ['list', 'search', 'filter'],
        statsStudents: ['list', 'search', 'filter'],
        olMain: ['list', 'view'],
        olSubjects: ['list', 'view'],
        olLectures: ['list', 'view'],
        olVideoMaterials: ['list', 'view'],
        olTesting: ['view', 'process'],
    },
    [UserRole.juniorLeader]: {
        laps: ['change'],
        dashboard: ['all'],
        students: ['list', 'search', 'filter'],
        employees: ['list', 'search', 'filter'],
        schools: ['list', 'search', 'filter'],
        dailySchedule: ['grid', 'timeline', 'pick-date', 'actions', 'change-view'],
        diarySchedule: ['list', 'view', 'pick-date', 'actions', 'filter', 'change-view'],
        evaluationPlatoons: ['list', 'view', 'pick-date'],
        evaluationStudents: ['list', 'view'],
        ratings: ['list'],
        wikiCategories: ['list'],
        wikiArticles: ['list'],
        statsLessons: ['list', 'search', 'filter'],
        statsStudents: ['list', 'search', 'filter'],
        olMain: ['list', 'view'],
        olSubjects: ['list', 'view'],
        olLectures: ['list', 'view'],
        olVideoMaterials: ['list', 'view'],
        olTesting: ['view', 'process'],
    },
    [UserRole.seniorInstructor]: {
        laps: ['change'],
        dashboard: ['all'],
        students: ['list', 'search', 'filter', 'export', 'edit'],
        employees: ['list', 'search', 'filter', 'create', 'edit'],
        schools: ['list', 'search', 'filter'],
        dailySchedule: ['grid', 'timeline', 'pick-date', 'actions', 'change-view'],
        diarySchedule: [
            'list',
            'view',
            'pick-date',
            'actions',
            'filter',
            'change-view',
            'export-timesheet',
            'export-schools',
            'set-discipline-score',
            'set-lesson-score',
            'bulk-set-lesson-score',
            'set-all-lesson-score',
        ],
        diaryLessonPlaces: ['list', 'view', 'create', 'edit', 'delete'],
        evaluationPlatoons: ['list', 'view', 'pick-date'],
        evaluationStudents: ['list', 'view'],
        ratings: ['list'],
        wikiCategories: ['all'],
        wikiArticles: ['all'],
        statsLessons: ['list', 'search', 'filter'],
        statsStudents: ['list', 'search', 'filter'],
        olMain: ['list', 'view'],
        olSubjects: ['list', 'view'],
        olLectures: ['list', 'view'],
        olVideoMaterials: ['list', 'view'],
        olTesting: ['view', 'process'],
    },
    [UserRole.psychologist]: {
        laps: ['change'],
        dashboard: ['all'],
        students: ['list', 'search', 'filter', 'export'],
        employees: ['list', 'search', 'filter'],
        schools: ['list', 'search', 'filter'],
        dailySchedule: ['grid', 'timeline', 'pick-date', 'actions', 'change-view'],
        diarySchedule: ['list', 'view', 'pick-date', 'actions', 'filter', 'change-view'],
        evaluationPlatoons: ['list', 'view', 'pick-date'],
        evaluationStudents: ['list', 'view', 'set', 'edit', 'delete'],
        ratings: ['list'],
        wikiCategories: ['all'],
        wikiArticles: ['all'],
        statsLessons: ['list', 'search', 'filter'],
        statsStudents: ['list', 'search', 'filter'],
        olMain: ['list', 'view'],
        olSubjects: ['list', 'view'],
        olLectures: ['list', 'view'],
        olVideoMaterials: ['list', 'view'],
        olTesting: ['view', 'process'],
    },
    [UserRole.organizingTeacher]: {
        laps: ['change'],
        dashboard: ['all'],
        students: [
            'list',
            'search',
            'filter',
            'create',
            'edit',
            'view-access',
            'edit-access',
            'send-access',
            'delete',
            'import',
            'export',
            'download-template',
        ],
        employees: ['list', 'search', 'filter', 'create', 'edit'],
        schools: ['list', 'search', 'filter'],
        dailySchedule: ['grid', 'timeline', 'pick-date', 'actions', 'change-view'],
        diarySchedule: [
            'list',
            'view',
            'pick-date',
            'actions',
            'filter',
            'change-view',
            'export-timesheet',
            'export-schools',
        ],
        evaluationPlatoons: ['list', 'view', 'pick-date', 'set', 'edit', 'delete'],
        evaluationStudents: ['list', 'view', 'set', 'edit', 'delete'],
        evaluationResponsibles: ['list', 'copy', 'edit', 'bulk-edit'],
        ratings: ['list'],
        wikiCategories: ['all'],
        wikiArticles: ['all'],
        statsLessons: ['list', 'search', 'filter'],
        statsStudents: ['list', 'search', 'filter'],
        olMain: ['list', 'view'],
        olSubjects: ['list', 'view'],
        olLectures: ['list', 'view'],
        olVideoMaterials: ['list', 'view'],
        olTesting: ['view', 'process'],
    },
};

const checkPermission = (role: UserRole | null, classifiersKey: string, classifier: string) => {
    if (!role) {
        return false;
    }

    const permissionsClassifiers: string[] = permissions[role][classifiersKey];

    if (permissionsClassifiers) {
        const hasPermission =
            permissionsClassifiers.includes('all') || permissionsClassifiers.includes(classifier);

        return hasPermission;
    }

    return false;
};

export { checkPermission };
