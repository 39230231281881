import { EntityList } from '@lib/entity';
import { LessonsStudentEntity } from './lessons-student-entity';

import type { Entity } from './lessons-student-entity';

class LessonsStudentEntityList extends EntityList<LessonsStudentEntity> {
    constructor(items: Entity[] | null, count?: number | null) {
        super(LessonsStudentEntity, items, count);
    }
}

export { LessonsStudentEntityList };
