import { ArrayHelper } from '@helpers';

import {
    $scheduleTypeActionStore,
    setScheduleTypeAction,
    resetScheduleTypeAction,
    $scheduleBulkLessonsStore,
    setScheduleBulkLesson,
    setScheduleBulkLessons,
    resetScheduleBulkLessons,
} from './';

$scheduleTypeActionStore
    .on(setScheduleTypeAction, (_, payload) => payload)
    .reset(resetScheduleTypeAction);

$scheduleBulkLessonsStore
    .on(setScheduleBulkLesson, (state, payload) => {
        let bulkLessons = [...state.bulkLessons];
        let idx = -1;

        const hasId = typeof payload.id !== 'undefined';

        if (hasId) {
            idx = bulkLessons.findIndex(item => item && item.id === payload.id);
        } else {
            idx = bulkLessons.findIndex(
                item =>
                    item &&
                    item.timeId === payload.timeId &&
                    item.company === payload.company &&
                    item.platoon === payload.platoon,
            );
        }

        if (idx > -1) {
            bulkLessons = ArrayHelper.remove(bulkLessons, idx);
        } else {
            bulkLessons = ArrayHelper.add(bulkLessons, payload);
        }

        const hasBulkLessons = bulkLessons.length !== 0;

        return {
            bulkLessons,
            hasBulkLessons,
        };
    })
    .on(setScheduleBulkLessons, (state, payload) => {
        const originBulkLessons = [...state.bulkLessons];

        const bulkLessons = payload.map(lesson => {
            const idx = originBulkLessons.findIndex(item => item && item.id === lesson.id);

            if (idx === -1) {
                return lesson;
            }

            return originBulkLessons[idx];
        });

        const hasBulkLessons = bulkLessons.length !== 0;

        return {
            bulkLessons,
            hasBulkLessons,
        };
    })
    .reset([resetScheduleBulkLessons, resetScheduleTypeAction]);
