import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core';
import { Tabs, Tab, TextField } from '@avangard/ui/core';

import { TranslationLocale, TranslationValue } from './types';

import type { TextFieldProps } from '@avangard/ui/core';

type TranslationEditorBaseProps = {
    values: TranslationValue[];
    onChange: (
        event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
        locale: TranslationLocale,
    ) => void;
};

type TranslationEditorTextFieldProps = Required<Pick<TextFieldProps, 'label'>> &
    Pick<TextFieldProps, 'id' | 'multiline' | 'placeholder' | 'error' | 'helperText' | 'onBlur'>;

type TranslationEditorProps = TranslationEditorBaseProps & TranslationEditorTextFieldProps;

const useTabsStyles = makeStyles({
    root: {
        minHeight: 40,
    },
});

const useTabStyles = makeStyles({
    root: {
        minWidth: 120,
        minHeight: 40,
        padding: '0 6px',
    },
    wrapper: {
        textAlign: 'center',
    },
});

const Root = styled.div``;

const Header = styled.div``;

const Main = styled.div`
    margin-top: 18px;
`;

const TranslationEditor = (props: TranslationEditorProps): React.ReactElement => {
    const {
        values,
        id,
        label,
        multiline = false,
        placeholder,
        error,
        helperText,
        onChange,
        onBlur,
    } = props;

    const { t } = useTranslation();

    const tabsClasses = useTabsStyles();
    const tabClasses = useTabStyles();

    const [locale, setLocale] = React.useState<TranslationLocale>('ru');

    const handleChangeTab = (_: unknown, value: TranslationLocale) => setLocale(value);

    const handleChange: TextFieldProps['onChange'] = event => {
        onChange(event, locale);
    };

    const handleBlur: TextFieldProps['onBlur'] = event => {
        if (onBlur) {
            onBlur(event);
        }
    };

    const translationValue = values.find(value => value.locale === locale);

    return (
        <Root>
            <Header>
                <Tabs classes={tabsClasses} value={locale} onChange={handleChangeTab}>
                    <Tab value='ru' label={t('common:blocks.languages.ru')} classes={tabClasses} />
                    <Tab value='en' label={t('common:blocks.languages.en')} classes={tabClasses} />
                </Tabs>
            </Header>

            <Main>
                <TextField
                    fullWidth
                    id={id}
                    label={label}
                    placeholder={placeholder}
                    multiline={multiline}
                    value={translationValue?.value ?? ''}
                    error={error}
                    helperText={helperText}
                    onChange={handleChange}
                    onBlur={handleBlur}
                />
            </Main>
        </Root>
    );
};

export { TranslationEditor };
