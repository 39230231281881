import { EntityList } from '@lib/entity';
import { LessonScoreItemEntity } from './lesson-score-item-entity';

import type { Entity } from './lesson-score-item-entity';

class LessonScoreItemEntityList extends EntityList<LessonScoreItemEntity> {
    constructor(items: Entity[] | null, count?: number | null) {
        super(LessonScoreItemEntity, items, count);
    }
}

export { LessonScoreItemEntityList };
