import { useTableFiltersQuery } from '@modules/layout/hooks';

import type { TableFiltersQuery } from '@modules/layout/hooks';

export type LapsTableFiltersQuery = Omit<TableFiltersQuery, 'filters'> & {
    filters: TableFiltersQuery['filters'];
};

const useLapsTableFiltersQuery = (): LapsTableFiltersQuery => {
    const { filters, query, ...otherTableFiltersQuery } = useTableFiltersQuery();

    const result: LapsTableFiltersQuery = {
        filters: {
            ...filters,
        },
        query,
        ...otherTableFiltersQuery,
    };

    return result;
};

export { useLapsTableFiltersQuery };
