import { EntityList } from '@lib/entity';
import { EmployeeEntity } from './employee.entity';

import type { Entity } from './employee.entity';

class EmployeeEntityList extends EntityList<EmployeeEntity> {
    constructor(items: Entity[] | null, count?: number | null) {
        super(EmployeeEntity, items, count);
    }
}

export { EmployeeEntityList };
