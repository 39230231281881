import { BaseEntity, Entity as DecribeEntity, RelationList } from '@lib/entity';

import { LessonsTableColumnEntityList } from './lessons-table-column-entity-list';

import type { EgLessonsTableRow } from '@modules/types/graphql';
import type { LessonsTableColumnEntity } from './lessons-table-column-entity';

export type Entity = Partial<EgLessonsTableRow> | null;

@DecribeEntity('LessonsTableRowEntity')
class LessonsTableRowEntity extends BaseEntity {
    company: number;
    platoon: number;
    platoonX: number;

    @RelationList(() => LessonsTableColumnEntityList)
    cols: LessonsTableColumnEntity[];

    constructor(lessonsTableRow: Entity) {
        super(lessonsTableRow);

        this.company = lessonsTableRow?.company ?? 0;
        this.platoon = lessonsTableRow?.platoon ?? 0;
        this.platoonX = lessonsTableRow?.platoonX ?? 0;
    }

    getCountLessons() {
        const count = this.cols.reduce((carry, col) => (col.hasLesson() ? carry + 1 : carry), 0);

        return count;
    }
}

export { LessonsTableRowEntity };
