import { BaseEntity, Entity as DecribeEntity, Relation, RelationList } from '@lib/entity';
import { i18n } from '@lib/i18n';
import { EmployeeEntityList } from '@modules/employees/entities';
import { TopicEntity } from '@modules/diary/subjects/entities';
import { egAttendanceShortTranslates } from '@modules/diary/schedule/constants';

import type { EmployeeEntity } from '@modules/employees/entities';
import type { EgLessonsStudentItem } from '@modules/types/graphql';

export type Entity = Partial<EgLessonsStudentItem> | null;

@DecribeEntity('LessonsStudentEntity')
class LessonsStudentEntity extends BaseEntity {
    time: string;
    subject: string;
    subjectColor: string;
    place: string;
    score: number | null;
    attendance: string;

    @RelationList(() => EmployeeEntityList)
    instructors: EmployeeEntity[];

    @Relation(() => TopicEntity)
    topic: TopicEntity;

    constructor(lessonsStudent: Entity) {
        super(lessonsStudent);

        this.time = lessonsStudent?.time ?? '';
        this.subject = lessonsStudent?.subject ?? '';
        this.subjectColor = lessonsStudent?.subjectColor ?? '';
        this.place = lessonsStudent?.place ?? '';
        this.score = lessonsStudent?.score || null;
        this.attendance = lessonsStudent?.attendance || '';
    }

    getSubjectLabel() {
        const label = (this.subject + ' ' + this.topic.name).trim();

        return label;
    }

    getInstructorsName() {
        const names = this.instructors.map(instructor => instructor.fullName || '-');

        return names;
    }

    getFormattedAttendance() {
        if (!this.attendance) {
            return this.getFieldOrDefaultValue('attendance');
        }

        return i18n.t(egAttendanceShortTranslates[this.attendance]);
    }
}

export { LessonsStudentEntity };
