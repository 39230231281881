import { useQuery } from '@apollo/client';

import { useEntity } from '@lib/entity';
import { LapEntityList } from '@modules/lap/entities';
import { GetAccessLapsQuery } from '@modules/lap/graphql';

import type { QueryHookOptions } from '@apollo/client';
import type { GetAccessLapsQueryType, GetAccessLapsQueryVariables } from '@modules/types/graphql';

const useAccessLaps = (
    options?: QueryHookOptions<GetAccessLapsQueryType, GetAccessLapsQueryVariables>,
) => {
    const queryResult = useQuery<GetAccessLapsQueryType, GetAccessLapsQueryVariables>(
        GetAccessLapsQuery,
        options,
    );

    const { entities: laps, count } = useEntity(
        () => new LapEntityList(queryResult.data?.getAccessLaps ?? null),
        [queryResult.data],
    );

    const result = {
        laps,
        count,
        ...queryResult,
    };

    return result;
};

export { useAccessLaps };
