import React from 'react';
import styled, { css } from 'styled-components';
import { format } from 'date-fns';
import { useLocation } from '@reach/router';
import { red } from '@avangard/ui/colors';
import { isRtl } from '@avangard/ui/utils';

import { useLocationQuery } from '@modules/layout/hooks';

type DaysListItem = {
    date: string;
    formattedDate: string;
};

type DayPickerProps = {
    daysList: DaysListItem[];
    filters: Record<string, any>;
    onSetDate: (query?: Record<string, any>) => void;
    visibleOnlyCurrent?: boolean;
    className?: string;
};

const Root = styled.div`
    max-width: 805px;
    overflow: auto;
    scrollbar-width: none;
`;

const Container = styled.div<Pick<DayPickerProps, 'visibleOnlyCurrent'>>`
    display: inline-flex;
    align-items: center;

    > * {
        &:first-child {
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
        }

        &:last-child {
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
        }

        &:not(:last-child) {
            border-right: 0;
        }

        ${isRtl`
            &:first-child {
                border-right: 1px solid;

                border-top-left-radius: 0;
                border-bottom-left-radius: 0;

                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px;
            }

            &:last-child {
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;

                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;

                border-right: 0;
            }
        `}
    }

    ${p =>
        !!p.visibleOnlyCurrent &&
        css`
            min-width: 120px;
        `}
`;

const PickItem = styled.div<{ current?: boolean; hide?: boolean }>`
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    width: 120px;
    height: 32px;
    color: ${red[100]};
    font-size: 1.4rem;
    line-height: 16px;
    border: 1px solid ${red[100]};
    background-color: transparent;
    transition: 0.2s background-color;
    cursor: pointer;

    &:hover {
        background-color: ${red[20]};
    }

    ${p =>
        !!p.hide &&
        css`
            display: none;
        `}

    ${p =>
        !!p.current &&
        css`
            display: flex;
            background-color: ${red[20]};

            ${!!p.hide &&
            css`
                border-right: 1px solid ${red[100]} !important;
                border-radius: 4px;
            `}
        `}
`;

const DayPicker = (props: DayPickerProps): React.ReactElement => {
    const { daysList, filters, visibleOnlyCurrent, onSetDate, ...otherProps } = props;

    const location = useLocation();
    const { query } = useLocationQuery();

    const handleClick = (date: string) => (): void => {
        if (filters.date !== date) {
            onSetDate({ ...query, date });
        }
    };

    React.useEffect(() => {
        const now = Date.now();
        const formattedNow = format(now, 'yyyy-MM-dd');

        if (filters.date || daysList.length === 0) {
            return;
        }

        let initialDate = daysList[0].date;

        if (daysList.findIndex(day => day.date === formattedNow) > -1) {
            initialDate = formattedNow;
        }

        onSetDate({ ...query, date: initialDate });
    }, [location]);

    return (
        <Root {...otherProps}>
            <Container visibleOnlyCurrent={visibleOnlyCurrent}>
                {daysList.map(dayList => (
                    <PickItem
                        key={dayList.date}
                        hide={visibleOnlyCurrent}
                        current={filters.date === dayList.date}
                        onClick={handleClick(dayList.date)}
                    >
                        {dayList.formattedDate}
                    </PickItem>
                ))}
            </Container>
        </Root>
    );
};

export { DayPicker };
