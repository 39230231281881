import { createStore, createEvent } from 'effector';
import { useStore } from 'effector-react';

import type { LessonsTableColumnEntity } from '@modules/diary/schedule/entities';

export type ScheduleMoveLesson = {
    id: string;
    timeId: string;
    platoon: number;
    company: number;
    details: LessonsTableColumnEntity;
};

type ScheduleMoveLessonStore = {
    from: ScheduleMoveLesson | null;
    to: ScheduleMoveLesson | null;
};

const initialStore = {
    from: null,
    to: null,
};

const pickScheduleMoveLesson = createEvent<ScheduleMoveLessonStore['from']>('pick schedule item');
const setScheduleMoveLesson = createEvent<ScheduleMoveLessonStore['to']>('set schedule item');
const $scheduleMoveLessonStore = createStore<ScheduleMoveLessonStore>(initialStore);

const useScheduleMoveLesson = (): ScheduleMoveLessonStore => useStore($scheduleMoveLessonStore);

export {
    $scheduleMoveLessonStore,
    useScheduleMoveLesson,
    pickScheduleMoveLesson,
    setScheduleMoveLesson,
};
