import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from '@apollo/client';

import { useEnqueueStacks } from '@modules/layout/hooks';
import { UpdateLapAccessRequest } from '@modules/lap/requests';
import { UpdateLapAccessMutation } from '@modules/lap/graphql';
import { LapAccessFormik } from './lap-access-formik';
import { LapAccessForm } from './lap-access-form';

import type { LapAccessEntity } from '@modules/lap/entities';
import type { UpdateLapAccessFormValues } from '@modules/lap/requests';
import {
    UpdateLapAccessMutationType,
    UpdateLapAccessMutationVariables,
} from '@modules/types/graphql';

export type LapAccessUpdateFormProps = {
    lapAccess: LapAccessEntity;
    onCancel: () => void;
    onSubmit?: () => Promise<void>;
};

const LapAccessUpdateForm = (props: LapAccessUpdateFormProps): React.ReactElement => {
    const { lapAccess, onCancel, onSubmit } = props;

    const { t } = useTranslation(['common', 'errors', 'access']);

    const { enqueueSuccess, enqueueError } = useEnqueueStacks();

    const [updateLapAccess] = useMutation<
        UpdateLapAccessMutationType,
        UpdateLapAccessMutationVariables
    >(UpdateLapAccessMutation);

    const initialValues: UpdateLapAccessFormValues = {
        fromLap: null,
        platform: lapAccess.lap.platform ?? '',
        course: lapAccess.lap.course,
        lap: lapAccess.lap,
    };

    const handleSubmit = async (values: UpdateLapAccessFormValues): Promise<void> => {
        try {
            const item = new UpdateLapAccessRequest(values);

            const { data: updateLapAccessData } = await updateLapAccess({
                variables: { id: lapAccess.id, item },
            });

            if (updateLapAccessData?.updateLapAccess?.id) {
                enqueueSuccess(t('access:notifiers.edit_access.success'));

                if (onSubmit) {
                    await onSubmit();
                }

                onCancel();
            } else {
                enqueueError(t('access:notifiers.edit_access.error'));
            }
        } catch (e) {
            throw e;
        }
    };

    return (
        <LapAccessFormik<UpdateLapAccessFormValues>
            initialValues={initialValues}
            onSubmit={handleSubmit}
        >
            <LapAccessForm submitText={t('common:actions.save')} onCancel={onCancel} />
        </LapAccessFormik>
    );
};

export { LapAccessUpdateForm };
