import { BaseEntity, Entity as DecribeEntity } from '@lib/entity';

import type { EgLessonPlace } from '@modules/types/graphql';

export type Entity = EgLessonPlace | null;

@DecribeEntity('EgLessonPlaceEntity')
class EgLessonPlaceEntity extends BaseEntity {
    id: string;
    name: string;
    shortName: string;

    constructor(subject: Entity) {
        super(subject);

        this.id = subject?.id ?? '';
        this.name = subject?.name ?? '';
        this.shortName = subject?.shortName ?? '';
    }

    exists() {
        const exists = this.id !== '';

        return exists;
    }
}

export { EgLessonPlaceEntity };
