import format from 'date-fns/format';

import { BaseEntity, Entity as DecribeEntity } from '@lib/entity';
import { getLocaleForDateFns } from '@lib/i18n';

import type { EgType, EgLessonTime } from '@modules/types/graphql';

export type Entity = Partial<EgLessonTime> | null;

@DecribeEntity('LessonTimeEntity')
class LessonTimeEntity extends BaseEntity {
    id: string;
    start: string;
    end: string;
    date: string;
    type: EgType | null;

    constructor(entity: Entity) {
        super(entity);

        this.id = entity?.id ?? '';
        this.start = entity?.start ?? '';
        this.end = entity?.end ?? '';
        this.date = entity?.date ?? '';
        this.type = entity?.type ?? null;
    }

    getFullTime() {
        const fullTime = `${this.start} - ${this.end}`;

        return fullTime;
    }

    getFullDate(longDay?: boolean) {
        const date = new Date(this.date);

        if (!date) {
            return '-';
        }

        const dayFormat = !!longDay ? 'EEEE' : 'EEEEEE';

        const fullDate = format(date, `${dayFormat}, dd.MM.yyyy`, {
            locale: getLocaleForDateFns(),
        });

        return fullDate;
    }
}

export { LessonTimeEntity };
