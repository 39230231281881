import { SchoolType } from '@modules/types/graphql';

export const schoolTypeTranslates = {
    [SchoolType.school]: 'common:blocks.school_types.school',
    [SchoolType.college]: 'common:blocks.school_types.college',
    [SchoolType.gymnasium]: 'common:blocks.school_types.gymnasium',
    [SchoolType.lyceum]: 'common:blocks.school_types.lyceum',
    [SchoolType.technical]: 'common:blocks.school_types.technical',
};

export const schoolTypesOptions = Object.values(SchoolType);
