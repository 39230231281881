import { BaseEntity, Entity as DecribeEntity } from '@lib/entity';
import { EgType } from '@modules/types/graphql';

import type { EgLessonsTableCol } from '@modules/types/graphql';

export type Entity = Partial<EgLessonsTableCol> | null;

@DecribeEntity('LessonsTableColumnEntity')
class LessonsTableColumnEntity extends BaseEntity {
    id: string;
    type: EgType | null;
    timeRange: string;
    index: number;
    name: string;
    color: string;
    instructors: (string | null)[];
    instructor: string;
    instructorId: string;
    place: string;
    studentsCount: number;
    scoresCount: number;
    averageScore: number;

    constructor(entity: Entity) {
        super(entity);

        this.id = entity?.id ?? '';
        this.type = entity?.type ?? null;
        this.timeRange = entity?.timeRange ?? '';
        this.index = entity?.index ?? 0;
        this.name = entity?.name ?? '';
        this.color = entity?.color ?? '';
        this.instructors = entity?.instructors ?? [];
        this.instructor = entity?.instructor ?? '';
        this.instructorId = entity?.instructorId ?? '';
        this.place = entity?.place ?? '';
        this.studentsCount = entity?.studentsCount ?? 0;
        this.scoresCount = entity?.scoresCount ?? 0;
        this.averageScore = entity?.averageScore ?? 0;
    }

    hasLesson() {
        const exists = this.id !== '';

        return exists;
    }

    isGradebook() {
        const result = this.type === EgType.gradebook;

        return result;
    }

    isDailySchedule() {
        const result = this.type === EgType.dailySchedule;

        return result;
    }

    getScoresTypes() {
        let type: 'normal' | 'settled' | 'unsettled' = 'settled';

        if (this.scoresCount === 0) {
            type = 'unsettled';
        }

        if (this.studentsCount === 0) {
            type = 'normal';
        }

        return type;
    }
}

export { LessonsTableColumnEntity };
