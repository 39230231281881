import React from 'react';
import styled, { css } from 'styled-components';
import { rgba } from 'polished';
import { useTranslation } from 'react-i18next';
import { useApolloClient } from '@apollo/client';
import { useTheme, useMediaQuery, Hidden, Divider } from '@material-ui/core';
import { Group, AmpStories, Person } from '@material-ui/icons';
import { white, red } from '@avangard/ui/colors';
import { breakpoints, isRtl } from '@avangard/ui/utils';

import { useNavigate, Link, getMenuRoutes, changePlatform, createRouteUrl } from '@lib/routing';
import { RouteMenuLocationEnum, RouteMenuSectionEnum, routes } from '@config/routes';
import { SidebarLogo } from '@modules/layout/icons';
import { ChangeLanguage } from '@modules/layout/moleculas';
import { logout } from '@modules/auth/helpers';
import { useCurrentRegionalCenter } from '@modules/regional-center/hooks';
import { RegionalCenterRegionsInfo } from '@modules/regional-center/moleculas';
import { useCurrentUser } from '@modules/user/hooks';
import { CurrentLapInfo } from '@modules/lap/organisms';

import type { LinkGetProps } from '@reach/router';

type SidebarProps = {
    open: boolean;
    onClose?: () => void;
};

const itemActiveStyles = {
    color: white[100],
    backgroundColor: red[100],
};

const Root = styled.aside<Pick<SidebarProps, 'open'>>`
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    width: 245px;
    height: 100%;
    background-image: url('/images/main-background.jpg');
    background-size: 100% auto;
    transition: 0.23s ease-in-out;
    z-index: 99;
    overflow-x: hidden;
    overflow-y: auto;

    ${breakpoints.down('sm')} {
        width: 100%;
        padding-top: 64px;
        padding-bottom: 74px;
        opacity: ${p => Number(p.open)};
        visibilty: ${p => (p.open ? 'visible' : 'hidden')};
        pointer-events: ${p => (p.open ? 'auto' : 'none')};
        transform: ${p => (p.open ? 'translateY(0)' : 'translateY(20px)')};
    }

    ${isRtl`
        left: initial;
        right: 0;
    `}
`;

const Header = styled.div`
    width: 100%;
    margin-bottom: 24px;
`;

const RegionalCenterRegionsInfoBlock = styled.div<{ variant?: 'primary' | 'secondary' }>`
    ${p =>
        (p.variant === 'primary' &&
            css`
                padding-inline-start: 16px;
            `) ||
        (p.variant === 'secondary' &&
            css`
                padding-inline-start: 4px;
                margin-bottom: 12px;
            `)}
`;

const CurrentLapInfoBlock = styled.div`
    width: calc(100% - 24px);
    margin: 0 auto 24px;
`;

const List = styled.div<{ withDivider?: boolean }>`
    display: flex;
    flex-wrap: wrap;
    position: relative;
    margin-top: 4px;

    > * {
        &:not(:last-of-type) {
            margin-bottom: 8px;
        }
    }

    ${p =>
        p.withDivider &&
        css`
            margin-top: 18px;
            padding-top: 18px;

            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 24px;
                width: calc(100% - 48px);
                height: 1px;
                background-color: ${rgba(white[100], 0.6)};
            }
        `}
`;

const ListItem = styled.div`
    cursor: pointer;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    height: 40px;
    padding: 0 16px 0 24px;
    color: ${white[60]};

    &:hover {
        color: ${white[100]};
    }
`;

const ListItemIcon = styled.div`
    display: flex;
    width: 16px;
    height: 16px;
`;

const ListItemContent = styled.div`
    width: calc(100% - 28px);
    margin-inline-start: 12px;
    font-size: 1.4rem;
    line-height: 16px;
`;

const StyledDivider = styled(Divider)`
    background-color: ${white[60]};
    opacity: 0.5;
    margin: 16px 24px;
`;

const Language = styled.div`
    margin-top: auto;
    padding-top: 20px;
    padding-inline-start: 20px;
`;

const Support = styled.div`
    padding: 20px;
    color: ${white[100]};
    font-size: 1.4rem;
    line-height: 20px;

    a {
        color: inherit;
    }
`;

const PolicyLink = styled.a`
    display: inline-block;
    color: rgba(255, 255, 255, 0.8);
    font-size: 1rem;
    line-height: 1.6;
    padding: 0 20px 20px;

    ${breakpoints.down('sm')} {
        padding: 20px;
    }

    &:hover {
        text-decoration: underline;
    }
`;

const personIcon = <Person style={{ fontSize: 18 }} />;
const groupIcon = <Group style={{ fontSize: 18 }} />;
const ampStoriesIcon = <AmpStories style={{ fontSize: 18 }} />;

const Sidebar = (props: SidebarProps): React.ReactElement => {
    const { open, onClose } = props;

    const { t } = useTranslation('common');

    const navigate = useNavigate();
    const apolloClient = useApolloClient();

    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });

    const { currentRegionalCenter, loading: currentRegionalCenterLoading } =
        useCurrentRegionalCenter();

    const { currentUser } = useCurrentUser();
    const currentUserRole = currentUser.getCurrentRole();

    const isCurrent = React.useCallback(
        (linkProps: LinkGetProps) => ({
            style: linkProps.isCurrent || linkProps.isPartiallyCurrent ? itemActiveStyles : {},
        }),
        [],
    );

    const profileLink = createRouteUrl(routes.profile);

    const handleClickChangePlatform = (): void => changePlatform();
    const handleClickLogout = (): Promise<void> => logout(apolloClient);

    const handleClickNavigate =
        (path: string) =>
        (event: React.MouseEvent<unknown>): void => {
            event.preventDefault();

            if (isMobile) {
                onClose?.();
            }

            navigate(path);
        };

    const menuRoutesLapDataOriented = getMenuRoutes(RouteMenuLocationEnum.sidebar, {
        forUser: currentUser,
        forSection: RouteMenuSectionEnum.lapDataOriented,
    });

    const menuRoutesRegionalCenterDataOriented = getMenuRoutes(RouteMenuLocationEnum.sidebar, {
        forUser: currentUser,
        forSection: RouteMenuSectionEnum.regionalCenterDataOriented,
    });

    return (
        <Root open={open}>
            <Hidden smDown>
                <Header>
                    <SidebarLogo />

                    {!currentRegionalCenterLoading ? (
                        <RegionalCenterRegionsInfoBlock variant='primary'>
                            <RegionalCenterRegionsInfo regionalCenter={currentRegionalCenter} />
                        </RegionalCenterRegionsInfoBlock>
                    ) : null}
                </Header>
            </Hidden>

            <CurrentLapInfoBlock>
                <Hidden mdUp>
                    {!currentRegionalCenterLoading ? (
                        <RegionalCenterRegionsInfoBlock variant='secondary'>
                            <RegionalCenterRegionsInfo regionalCenter={currentRegionalCenter} />
                        </RegionalCenterRegionsInfoBlock>
                    ) : null}
                </Hidden>

                <CurrentLapInfo />
            </CurrentLapInfoBlock>

            {menuRoutesLapDataOriented.length !== 0 ? (
                <List>
                    {menuRoutesLapDataOriented.map(menuRoute => {
                        const Icon = menuRoute.menuIcon;

                        const originMenuTitle = menuRoute.menuTitle ?? menuRoute.title;

                        let menuTitle = originMenuTitle;

                        if (currentUserRole && menuRoute.menuTitleFor?.[currentUserRole]) {
                            menuTitle = menuRoute.menuTitleFor[currentUserRole] ?? originMenuTitle;
                        }

                        return (
                            <ListItem
                                as={Link}
                                key={menuRoute.name}
                                to={menuRoute.path}
                                getProps={isCurrent}
                                onClick={handleClickNavigate(menuRoute.path)}
                            >
                                <ListItemIcon>
                                    {Icon ? <Icon style={{ fontSize: 16 }} /> : null}
                                </ListItemIcon>

                                <ListItemContent>{t(menuTitle)}</ListItemContent>
                            </ListItem>
                        );
                    })}
                </List>
            ) : null}

            {menuRoutesRegionalCenterDataOriented.length !== 0 ? (
                <List withDivider>
                    {menuRoutesRegionalCenterDataOriented.map(menuRoute => {
                        const Icon = menuRoute.menuIcon;

                        const originMenuTitle = menuRoute.menuTitle ?? menuRoute.title;

                        let menuTitle = originMenuTitle;

                        if (currentUserRole && menuRoute.menuTitleFor?.[currentUserRole]) {
                            menuTitle = menuRoute.menuTitleFor[currentUserRole] ?? originMenuTitle;
                        }

                        return (
                            <ListItem
                                as={Link}
                                key={menuRoute.name}
                                to={menuRoute.path}
                                getProps={isCurrent}
                                onClick={handleClickNavigate(menuRoute.path)}
                            >
                                <ListItemIcon>
                                    {Icon ? <Icon style={{ fontSize: 16 }} /> : null}
                                </ListItemIcon>

                                <ListItemContent>{t(menuTitle)}</ListItemContent>
                            </ListItem>
                        );
                    })}
                </List>
            ) : null}

            <Hidden mdUp>
                <StyledDivider light variant='middle' />

                <List>
                    <ListItem as='div' onClick={handleClickNavigate(profileLink)}>
                        <ListItemIcon>{personIcon}</ListItemIcon>
                        <ListItemContent>{t('user.profile')}</ListItemContent>
                    </ListItem>

                    <ListItem as='div' onClick={handleClickChangePlatform}>
                        <ListItemIcon>{ampStoriesIcon}</ListItemIcon>
                        <ListItemContent>{t('user.change_platform')}</ListItemContent>
                    </ListItem>

                    <ListItem as='div' onClick={handleClickLogout}>
                        <ListItemIcon>{groupIcon}</ListItemIcon>
                        <ListItemContent>{t('actions.exit')}</ListItemContent>
                    </ListItem>
                </List>
            </Hidden>

            <Language>
                <ChangeLanguage />
            </Language>

            <Hidden smDown>
                <Support>
                    <p>
                        {t('support.ask')} <br />
                        <a href='mailto:info@avangard-online.ru'>info@avangard-online.ru</a>
                    </p>
                </Support>
            </Hidden>

            <PolicyLink
                href='https://storage.yandexcloud.net/avangard-static/obrabotka-personalnih-dannih.pdf'
                target='_blank'
            >
                {t('support.policy_term')}
            </PolicyLink>
        </Root>
    );
};

export { Sidebar };
