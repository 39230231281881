import { EntityList } from '@lib/entity';
import { EgSubjectEntity } from './eg-subject-entity';

import type { Entity } from './eg-subject-entity';

class EgSubjectEntityList extends EntityList<EgSubjectEntity> {
    constructor(items: Entity[] | null, count?: number | null) {
        super(EgSubjectEntity, items, count);
    }
}

export { EgSubjectEntityList };
