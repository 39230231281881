import loadable from '@loadable/component';
import {
    Assignment,
    AssignmentTurnedIn,
    Book,
    ChromeReaderMode,
    Dashboard,
    DateRange,
    Group,
    LocalLibrary,
    PieChart,
    Poll,
    Laptop,
    Ballot,
    School,
    WatchLater,
    FolderOpen,
    Stars,
    Settings,
} from '@material-ui/icons';

import { BaseLayout, EmptyLayout, ErrorLayout } from '@modules/layout/templates';
import { UserGrantCategoryToken, UserRole } from '@modules/types/graphql';

import type { RedirectProps, RouteComponentProps } from '@reach/router';
import type { LoadableComponent } from '@loadable/component';

export enum RouteMenuLocationEnum {
    sidebar = 'sidebar',
}

export enum RouteMenuSectionEnum {
    lapDataOriented = 'lapDataOriented',
    regionalCenterDataOriented = 'regionalCenterDataOriented',
}

export type OriginRoute = {
    name: string;
    title: string;
    path: string;
    private: boolean;
    LayoutComponent: React.FunctionComponent<any>;
    componentPath?: string;
    renderForPath?: Partial<Record<UserRole, string>>;
    redirectTo?: string;
    withBackIcon?: boolean;
    default?: boolean;
    categoryGrant?: UserGrantCategoryToken;
    categoryGrantFor?: Partial<Record<UserRole, UserGrantCategoryToken>>;
    roles?: UserRole[];
    titleFor?: Partial<Record<UserRole, string>>;
    menuTitle?: string;
    menuTitleFor?: Partial<Record<UserRole, string>>;
    menuLocations?: RouteMenuLocationEnum[];
    menuSections?: RouteMenuSectionEnum[];
    menuIcon?: React.FunctionComponent<any>;
    children?: React.ReactNode;
};

export type Route = Omit<OriginRoute, 'renderForPath' | 'componentPath'> & {
    renderFor?: Partial<Record<UserRole, LoadableComponent<any>>>;
    Component?: LoadableComponent<any>;
};

export type OriginRoutes = Record<string, OriginRoute>;
export type ConfigRoutes = Record<string, Route>;

export type RedirectRoute = RouteComponentProps<RedirectProps<{}>> & {
    name: string;
};

const originRoutes: OriginRoutes = {
    index: {
        name: 'index',
        title: 'index',
        path: '/',
        private: true,
        componentPath: '@pages',
        LayoutComponent: EmptyLayout,
    },

    summary: {
        name: 'summary.index',
        title: 'common:menu.summary',
        path: '/summary',
        private: true,
        categoryGrant: UserGrantCategoryToken.summaryResult,
        menuLocations: [RouteMenuLocationEnum.sidebar],
        menuSections: [RouteMenuSectionEnum.regionalCenterDataOriented],
        menuIcon: AssignmentTurnedIn,
        componentPath: '@pages/summary',
        LayoutComponent: BaseLayout,
    },
    summarySchools: {
        name: 'summary.schools.index',
        title: 'common:menu.schools',
        path: '/summary/schools',
        private: true,
        withBackIcon: true,
        categoryGrant: UserGrantCategoryToken.summaryResult,
        componentPath: '@pages/summary/schools',
        LayoutComponent: BaseLayout,
    },
    summarySchoolsEditPage: {
        name: 'summary.schools.edit',
        title: 'common:actions.edit',
        path: '/summary/schools/:id/edit',
        private: true,
        withBackIcon: true,
        categoryGrant: UserGrantCategoryToken.summaryResult,
        componentPath: '@pages/summary/schools/edit',
        LayoutComponent: BaseLayout,
    },
    summaryStudents: {
        name: 'summary.students.index',
        title: 'common:menu.students',
        path: '/summary/students',
        private: true,
        withBackIcon: true,
        categoryGrant: UserGrantCategoryToken.summaryResult,
        componentPath: '@pages/summary/students',
        LayoutComponent: BaseLayout,
    },
    summaryStudentsSinglePage: {
        name: 'summary.students.single',
        title: '',
        path: '/summary/students/:id',
        private: true,
        withBackIcon: true,
        categoryGrant: UserGrantCategoryToken.summaryResult,
        componentPath: '@pages/summary/students/single',
        LayoutComponent: BaseLayout,
    },
    summaryStudentsEditPage: {
        name: 'summary.students.edit',
        title: 'common:actions.edit',
        path: '/summary/students/:id/edit',
        private: true,
        withBackIcon: true,
        categoryGrant: UserGrantCategoryToken.summaryResult,
        componentPath: '@pages/summary/students/edit',
        LayoutComponent: BaseLayout,
    },

    dashboard: {
        name: 'dashboard.index',
        title: 'common:menu.dashboard',
        path: '/dashboard',
        private: true,
        categoryGrant: UserGrantCategoryToken.cdb,
        menuLocations: [RouteMenuLocationEnum.sidebar],
        menuSections: [RouteMenuSectionEnum.lapDataOriented],
        menuIcon: Dashboard,
        componentPath: '@pages/dashboard',
        LayoutComponent: BaseLayout,
    },

    actionLog: {
        name: 'actionLog.index',
        title: 'common:menu.action_log',
        menuTitle: 'common:menu.action_log',
        path: '/action-log',
        private: true,
        categoryGrant: UserGrantCategoryToken.actionLog,
        menuLocations: [RouteMenuLocationEnum.sidebar],
        menuSections: [RouteMenuSectionEnum.lapDataOriented],
        menuIcon: Ballot,
        componentPath: '@pages/action-log',
        LayoutComponent: BaseLayout,
    },

    students: {
        name: 'students.index',
        title: 'common:menu.students',
        path: '/students',
        private: true,
        categoryGrant: UserGrantCategoryToken.student,
        menuLocations: [RouteMenuLocationEnum.sidebar],
        menuSections: [RouteMenuSectionEnum.lapDataOriented],
        menuIcon: Group,
        componentPath: '@pages/students',
        LayoutComponent: BaseLayout,
    },
    studentsSinglePage: {
        name: 'students.single',
        title: '',
        path: '/students/:id',
        private: true,
        withBackIcon: true,
        categoryGrant: UserGrantCategoryToken.student,
        componentPath: '@pages/students/single',
        LayoutComponent: BaseLayout,
    },
    studentsEditPage: {
        name: 'students.edit',
        title: 'common:actions.edit',
        path: '/students/:id/edit',
        private: true,
        withBackIcon: true,
        categoryGrant: UserGrantCategoryToken.student,
        componentPath: '@pages/students/edit',
        LayoutComponent: BaseLayout,
    },

    employees: {
        name: 'employees.index',
        title: 'common:menu.employees',
        path: '/employees',
        private: true,
        categoryGrant: UserGrantCategoryToken.employee,
        menuLocations: [RouteMenuLocationEnum.sidebar],
        menuSections: [RouteMenuSectionEnum.regionalCenterDataOriented],
        menuIcon: LocalLibrary,
        componentPath: '@pages/employees',
        LayoutComponent: BaseLayout,
    },
    employeesEditPage: {
        name: 'employees.edit',
        title: 'common:actions.edit',
        path: '/employees/:id/edit',
        private: true,
        withBackIcon: true,
        categoryGrant: UserGrantCategoryToken.employee,
        componentPath: '@pages/employees/edit',
        LayoutComponent: BaseLayout,
    },

    schools: {
        name: 'schools.index',
        title: 'common:menu.schools',
        menuTitle: 'common:menu.short_schools',
        path: '/schools',
        private: true,
        categoryGrant: UserGrantCategoryToken.school,
        menuLocations: [RouteMenuLocationEnum.sidebar],
        menuSections: [RouteMenuSectionEnum.lapDataOriented],
        menuIcon: Book,
        componentPath: '@pages/schools',
        LayoutComponent: BaseLayout,
    },
    schoolsEditPage: {
        name: 'schools.edit',
        title: 'common:actions.edit',
        path: '/schools/:id/edit',
        private: true,
        withBackIcon: true,
        categoryGrant: UserGrantCategoryToken.school,
        componentPath: '@pages/schools/edit',
        LayoutComponent: BaseLayout,
    },

    diary: {
        name: 'diary.index',
        title: '',
        menuTitle: 'common:menu.diary',
        path: '/diary',
        menuTitleFor: {
            [UserRole.student]: 'common:menu.diary_for_students',
        },
        private: true,
        categoryGrant: UserGrantCategoryToken.eg,
        menuLocations: [RouteMenuLocationEnum.sidebar],
        menuSections: [RouteMenuSectionEnum.lapDataOriented],
        menuIcon: ChromeReaderMode,
        componentPath: '@pages/diary',
        LayoutComponent: BaseLayout,
    },
    diarySchedule: {
        name: 'diary.schedule.index',
        title: 'common:menu.diary',
        menuTitle: 'common:menu.schedule',
        path: '/diary/schedule',
        private: true,
        categoryGrant: UserGrantCategoryToken.egSchedule,
        titleFor: {
            [UserRole.student]: 'common:menu.diary_for_students',
        },
        renderForPath: {
            [UserRole.school]: '@pages/diary/schedule/school',
            [UserRole.student]: '@pages/diary/schedule/student',
        },
        componentPath: '@pages/diary/schedule',
        LayoutComponent: BaseLayout,
    },
    diaryScheduleSingle: {
        name: 'diary.schedule.single',
        title: '',
        path: '/diary/schedule/:id',
        private: true,
        withBackIcon: true,
        categoryGrant: UserGrantCategoryToken.egSchedule,
        componentPath: '@pages/diary/schedule/single',
        LayoutComponent: BaseLayout,
    },
    diarySubjects: {
        name: 'diary.subjects.index',
        title: 'common:menu.diary',
        menuTitle: 'common:menu.subjects',
        path: '/diary/subjects',
        private: true,
        categoryGrant: UserGrantCategoryToken.egSubject,
        componentPath: '@pages/diary/subjects',
        LayoutComponent: BaseLayout,
    },
    diarySubjectsEditPage: {
        name: 'diary.subjects.edit',
        title: 'common:actions.edit',
        path: '/diary/subjects/:id',
        private: true,
        withBackIcon: true,
        categoryGrant: UserGrantCategoryToken.egSubject,
        componentPath: '@pages/diary/subjects/edit',
        LayoutComponent: BaseLayout,
    },
    diaryLessonPlaces: {
        name: 'diary.lesson-places.index',
        title: 'common:menu.diary',
        menuTitle: 'common:menu.lessons_places',
        path: '/diary/lesson-places',
        private: true,
        categoryGrant: UserGrantCategoryToken.egLessonPlace,
        componentPath: '@pages/diary/lesson-places',
        LayoutComponent: BaseLayout,
    },
    diaryModules: {
        name: 'diary.modules.index',
        title: 'common:menu.diary',
        menuTitle: 'common:menu.modules',
        path: '/diary/modules',
        private: true,
        categoryGrant: UserGrantCategoryToken.egModule,
        componentPath: '@pages/diary/modules',
        LayoutComponent: BaseLayout,
    },
    diaryModulesEditPage: {
        name: 'diary.modules.edit',
        title: 'common:actions.edit',
        path: '/diary/modules/:id',
        private: true,
        withBackIcon: true,
        categoryGrant: UserGrantCategoryToken.egModule,
        componentPath: '@pages/diary/modules/edit',
        LayoutComponent: BaseLayout,
    },

    dailySchedule: {
        name: 'daily-schedule.index',
        title: 'common:menu.daily_schedule',
        path: '/daily-schedule',
        private: true,
        categoryGrant: UserGrantCategoryToken.dailySchedule,
        componentPath: '@pages/daily-schedule',
        menuLocations: [RouteMenuLocationEnum.sidebar],
        menuSections: [RouteMenuSectionEnum.lapDataOriented],
        menuIcon: WatchLater,
        LayoutComponent: BaseLayout,
    },

    evaluation: {
        name: 'evaluation.index',
        title: '',
        menuTitle: 'common:menu.evaluation',
        path: '/evaluation',
        redirectTo: '/evaluation/platoons',
        private: true,
        categoryGrant: UserGrantCategoryToken.evaluation,
        menuLocations: [RouteMenuLocationEnum.sidebar],
        menuSections: [RouteMenuSectionEnum.lapDataOriented],
        menuIcon: Assignment,
        LayoutComponent: BaseLayout,
    },
    evaluationPlatoons: {
        name: 'evaluation.platoons.index',
        title: 'common:menu.evaluation',
        menuTitle: 'common:menu.platoons',
        path: '/evaluation/platoons',
        private: true,
        categoryGrant: UserGrantCategoryToken.evaluationPlatoon,
        componentPath: '@pages/evaluation/platoons',
        LayoutComponent: BaseLayout,
    },
    evaluationPlatoonsSingle: {
        name: 'evaluation.platoons.single',
        title: '',
        path: '/evaluation/platoons/:id',
        private: true,
        withBackIcon: true,
        categoryGrant: UserGrantCategoryToken.evaluationPlatoon,
        componentPath: '@pages/evaluation/platoons/single',
        LayoutComponent: BaseLayout,
    },
    evaluationStudents: {
        name: 'evaluation.students.index',
        title: 'common:menu.evaluation',
        menuTitle: 'common:menu.students',
        path: '/evaluation/students',
        private: true,
        categoryGrant: UserGrantCategoryToken.evaluationStudent,
        componentPath: '@pages/evaluation/students',
        LayoutComponent: BaseLayout,
    },
    evaluationStudentsSingle: {
        name: 'evaluation.students.single',
        title: '',
        path: '/evaluation/students/:id',
        private: true,
        withBackIcon: true,
        categoryGrant: UserGrantCategoryToken.evaluationStudent,
        componentPath: '@pages/evaluation/students/single',
        LayoutComponent: BaseLayout,
    },
    evaluationCriteriaPlatoons: {
        name: 'evaluation.criteria-platoons.index',
        title: 'common:menu.evaluation',
        menuTitle: 'common:menu.criteria_platoons',
        path: '/evaluation/criteria-platoons',
        private: true,
        categoryGrant: UserGrantCategoryToken.evaluationCriteriaPlatoon,
        componentPath: '@pages/evaluation/criteria/platoons',
        LayoutComponent: BaseLayout,
    },
    evaluationCriteriaStudents: {
        name: 'evaluation.criteria-students.index',
        title: 'common:menu.evaluation',
        menuTitle: 'common:menu.criteria_students',
        path: '/evaluation/criteria-students',
        private: true,
        categoryGrant: UserGrantCategoryToken.evaluationCriteriaStudent,
        componentPath: '@pages/evaluation/criteria/students',
        LayoutComponent: BaseLayout,
    },
    evaluationResponsibles: {
        name: 'evaluation.responsibles.index',
        title: 'common:menu.evaluation',
        menuTitle: 'common:menu.responsibles',
        path: '/evaluation/responsibles',
        private: true,
        categoryGrant: UserGrantCategoryToken.evaluationResponsible,
        componentPath: '@pages/evaluation/responsibles',
        LayoutComponent: BaseLayout,
    },

    stats: {
        name: 'stats.index',
        title: 'common:menu.stats',
        path: '/stats',
        private: true,
        categoryGrant: UserGrantCategoryToken.stat,
        menuLocations: [RouteMenuLocationEnum.sidebar],
        menuSections: [RouteMenuSectionEnum.lapDataOriented],
        menuIcon: PieChart,
        componentPath: '@pages/stats',
        LayoutComponent: BaseLayout,
    },
    statsLessons: {
        name: 'stats.lessons.index',
        title: 'common:menu.stats',
        menuTitle: 'common:menu.stats_average_lessons',
        path: '/stats/lessons',
        private: true,
        categoryGrant: UserGrantCategoryToken.statLesson,
        componentPath: '@pages/stats/lessons',
        LayoutComponent: BaseLayout,
    },
    statsStudents: {
        name: 'stats.students.index',
        title: 'common:menu.stats',
        menuTitle: 'common:menu.stats_students',
        path: '/stats/students',
        private: true,
        categoryGrant: UserGrantCategoryToken.statStudent,
        componentPath: '@pages/stats/students',
        LayoutComponent: BaseLayout,
    },

    ratings: {
        name: 'ratings.index',
        title: 'common:menu.ratings',
        path: '/ratings',
        private: true,
        categoryGrant: UserGrantCategoryToken.rating,
        menuLocations: [RouteMenuLocationEnum.sidebar],
        menuSections: [RouteMenuSectionEnum.lapDataOriented],
        menuIcon: Poll,
        componentPath: '@pages/ratings',
        LayoutComponent: BaseLayout,
    },

    events: {
        name: 'events.index',
        title: 'common:menu.events',
        path: '/events',
        private: true,
        categoryGrant: UserGrantCategoryToken.event,
        menuLocations: [RouteMenuLocationEnum.sidebar],
        menuSections: [RouteMenuSectionEnum.lapDataOriented],
        menuIcon: Stars,
        componentPath: '@pages/events',
        LayoutComponent: BaseLayout,
    },

    onlineLearning: {
        name: 'online-learning.index',
        title: 'common:menu.testing',
        path: '/online-learning',
        private: true,
        categoryGrant: UserGrantCategoryToken.olTesting,
        categoryGrantFor: {
            [UserRole.superAdmin]: UserGrantCategoryToken.olSubject,
            [UserRole.admin]: UserGrantCategoryToken.olSubject,
        },
        menuLocations: [RouteMenuLocationEnum.sidebar],
        menuSections: [RouteMenuSectionEnum.regionalCenterDataOriented],
        menuIcon: Laptop,
        componentPath: '@pages/online-learning/subjects/employee',
        renderForPath: {
            [UserRole.superAdmin]: '@pages/online-learning/subjects/admin',
            [UserRole.admin]: '@pages/online-learning/subjects/admin',
        },
        LayoutComponent: BaseLayout,
    },
    subject: {
        name: 'subject.show',
        title: '',
        path: '/online-learning/:subjectId/show',
        private: true,
        withBackIcon: true,
        categoryGrant: UserGrantCategoryToken.olTesting,
        categoryGrantFor: {
            [UserRole.superAdmin]: UserGrantCategoryToken.olSubject,
            [UserRole.admin]: UserGrantCategoryToken.olSubject,
        },
        componentPath: '@pages/online-learning/subjects/show',
        LayoutComponent: BaseLayout,
    },
    subjectEditPage: {
        name: 'subject.edit',
        title: '',
        path: '/online-learning/:subjectId/edit',
        private: true,
        withBackIcon: true,
        categoryGrant: UserGrantCategoryToken.olSubject,
        componentPath: '@pages/online-learning/subjects/edit',
        LayoutComponent: BaseLayout,
    },
    lecture: {
        name: 'lecture.index',
        title: '',
        path: '/online-learning/:subjectId/lecture/:id',
        private: true,
        withBackIcon: true,
        categoryGrant: UserGrantCategoryToken.olSubject,
        componentPath: '@pages/online-learning/lectures/show',
        LayoutComponent: BaseLayout,
    },
    lectureEditPage: {
        name: 'lecture.edit',
        title: '',
        path: '/online-learning/:subjectId/lecture/:id/edit',
        private: true,
        withBackIcon: true,
        categoryGrant: UserGrantCategoryToken.olSubject,
        componentPath: '@pages/online-learning/lectures/edit',
        LayoutComponent: BaseLayout,
    },

    testing: {
        name: 'testing.index',
        title: 'common:menu.testing',
        path: '/online-learning/testing',
        private: true,
        withBackIcon: true,
        categoryGrant: UserGrantCategoryToken.olTesting,
        componentPath: '@pages/online-learning/testing',
        LayoutComponent: BaseLayout,
    },

    wiki: {
        name: 'wiki.index',
        title: 'common:menu.wiki',
        path: '/wiki',
        private: true,
        categoryGrant: UserGrantCategoryToken.wiki,
        menuLocations: [RouteMenuLocationEnum.sidebar],
        menuSections: [RouteMenuSectionEnum.regionalCenterDataOriented],
        menuIcon: School,
        componentPath: '@pages/wiki',
        LayoutComponent: BaseLayout,
    },
    wikiArticles: {
        name: 'wiki.articles.index',
        title: '',
        path: '/wiki/:categoryId/articles',
        private: true,
        withBackIcon: true,
        categoryGrant: UserGrantCategoryToken.wiki,
        componentPath: '@pages/wiki/articles',
        LayoutComponent: BaseLayout,
    },
    wikiArticle: {
        name: 'wiki.article.index',
        title: '',
        path: '/wiki/:categoryId/articles/:articleId',
        private: true,
        withBackIcon: true,
        categoryGrant: UserGrantCategoryToken.wiki,
        componentPath: '@pages/wiki/articles/show',
        LayoutComponent: BaseLayout,
    },
    wikiArticleEditPage: {
        name: 'wiki.article.edit',
        title: 'common:menu.wiki_edit',
        path: '/wiki/:categoryId/articles/:articleId/edit',
        private: true,
        withBackIcon: true,
        categoryGrant: UserGrantCategoryToken.wiki,
        componentPath: '@pages/wiki/articles/edit',
        LayoutComponent: BaseLayout,
    },
    wikiArticleCreatePage: {
        name: 'wiki.article.create',
        title: 'common:menu.wiki_create',
        path: '/wiki/:categoryId/articles/create',
        private: true,
        withBackIcon: true,
        categoryGrant: UserGrantCategoryToken.wiki,
        componentPath: '@pages/wiki/articles/create',
        LayoutComponent: BaseLayout,
    },

    handbook: {
        name: 'handbook.index',
        title: 'common:menu.handbook',
        path: '/handbook',
        private: true,
        categoryGrant: UserGrantCategoryToken.handbook,
        menuLocations: [RouteMenuLocationEnum.sidebar],
        menuSections: [RouteMenuSectionEnum.regionalCenterDataOriented],
        menuIcon: FolderOpen,
        componentPath: '@pages/handbook',
        LayoutComponent: BaseLayout,
    },
    handbookStudentsSinglePage: {
        name: 'students.single',
        title: '',
        path: '/handbook/students/:id',
        private: true,
        withBackIcon: true,
        categoryGrant: UserGrantCategoryToken.handbookStudent,
        componentPath: '@pages/handbook/students/single',
        LayoutComponent: BaseLayout,
    },
    handbookStudentsEditPage: {
        name: 'students.edit',
        title: 'common:actions.edit',
        path: '/handbook/students/:id/edit',
        private: true,
        withBackIcon: true,
        categoryGrant: UserGrantCategoryToken.handbookStudent,
        componentPath: '@pages/handbook/students/edit',
        LayoutComponent: BaseLayout,
    },
    handbookSchoolsEditPage: {
        name: 'handbook.schools.edit',
        title: 'common:actions.edit',
        path: '/handbook/schools/:id/edit',
        private: true,
        withBackIcon: true,
        categoryGrant: UserGrantCategoryToken.handbookSchool,
        componentPath: '@pages/handbook/schools/edit',
        LayoutComponent: BaseLayout,
    },

    laps: {
        name: 'laps.index',
        title: 'common:menu.laps',
        path: '/laps',
        private: true,
        categoryGrant: UserGrantCategoryToken.lap,
        menuLocations: [RouteMenuLocationEnum.sidebar],
        menuSections: [RouteMenuSectionEnum.regionalCenterDataOriented],
        menuIcon: DateRange,
        componentPath: '@pages/laps',
        LayoutComponent: BaseLayout,
    },

    settings: {
        name: 'settings.index',
        title: 'common:menu.settings',
        path: '/settings',
        private: true,
        roles: [UserRole.superAdmin, UserRole.admin],
        menuLocations: [RouteMenuLocationEnum.sidebar],
        menuSections: [RouteMenuSectionEnum.regionalCenterDataOriented],
        menuIcon: Settings,
        componentPath: '@pages/settings',
        LayoutComponent: BaseLayout,
    },

    profile: {
        name: 'profile.index',
        title: 'common:menu.profile',
        path: '/profile',
        private: true,
        categoryGrant: UserGrantCategoryToken.profile,
        componentPath: '@pages/profile',
        LayoutComponent: BaseLayout,
    },

    error: {
        name: 'error',
        title: 'Error Page',
        path: '/error',
        private: false,
        componentPath: '@pages/error', // TODO: Create Error page
        LayoutComponent: ErrorLayout,
    },
};

const routes = Object.entries(originRoutes).reduce((carry, [key, route]) => {
    let asyncComponent: Route['Component'] = undefined;
    let renderFor: Route['renderFor'] = undefined;

    if (route.componentPath) {
        const path = route.componentPath.replace('@', '');

        asyncComponent = loadable(() => import(/* webpackPrefetch: true */ `../${path}`));
    }

    if (route.renderForPath) {
        renderFor = Object.entries(route.renderForPath).reduce((carry, [role, componentPath]) => {
            if (componentPath) {
                const path = componentPath.replace('@', '');
                const asyncComponent = loadable(
                    () => import(/* webpackPrefetch: true */ `../${path}`),
                );

                return { ...carry, [role]: asyncComponent };
            }

            return carry;
        }, {});
    }

    return {
        ...carry,
        [key]: { ...route, renderFor, Component: asyncComponent },
    };
}, {} as ConfigRoutes);

const redirects: RedirectRoute[] = [
    {
        name: 'default',
        from: '/:lang',
        to: routes.index.path,
        default: true,
    },
];

export { routes, redirects };
