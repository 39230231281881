import { EntityList } from '@lib/entity';
import { LessonsTableColumnEntity } from './lessons-table-column-entity';

import type { Entity } from './lessons-table-column-entity';

class LessonsTableColumnEntityList extends EntityList<LessonsTableColumnEntity> {
    constructor(items: Entity[] | null, count?: number | null) {
        super(LessonsTableColumnEntity, items, count);
    }
}

export { LessonsTableColumnEntityList };
