import { endOfDay, format } from 'date-fns';

import type { LapProgramType, LapUpdateArgs } from '@modules/types/graphql';

export type UpdateLapFormValues = {
    programType: LapProgramType | '';
    from: Date | null;
    to: Date | null;
};

class UpdateLapRequest {
    programType: LapUpdateArgs['programType'];
    from: LapUpdateArgs['from'];
    to: LapUpdateArgs['to'];

    constructor(values: UpdateLapFormValues) {
        this.programType = (values.programType as LapProgramType) || undefined;
        this.from = values.from ? format(endOfDay(values.from), 'yyyy-MM-dd') : '';
        this.to = values.to ? format(endOfDay(values.to), 'yyyy-MM-dd') : '';
    }
}

export { UpdateLapRequest };
