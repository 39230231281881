import { gql } from '@apollo/client';

export const GetCurrentUserQuery = gql`
    query GetCurrentUser {
        getCurrentUser {
            id
            username
            fullName
            email
            roles
            createdAt
            updatedAt
            student {
                id
                fullName
            }
            school {
                id
                shortName
                currentStudentsCount
                maxStudentsCount
                studentsAddMode
                canDeleteStudent
            }
            grants {
                category
                actions
            }
        }
    }
`;
