import React from 'react';

import { useLazySchools } from '@modules/shared/hooks';

import type { SchoolCollectionFilter } from '@modules/types/graphql';

type UseSchoolsFilterOptions = {
    refLocationId?: string;
    filter?: SchoolCollectionFilter;
};

const useSchoolsFilter = (options: UseSchoolsFilterOptions) => {
    const { refLocationId, filter } = options;

    const [getSchools, { schools, count: schoolsCount, loading: schoolsLoading }] =
        useLazySchools();

    React.useEffect(() => {
        if (!refLocationId) {
            return;
        }

        getSchools({
            variables: {
                args: {
                    filter: {
                        location: { id: refLocationId },
                        ...filter,
                    },
                    page: { all: true },
                },
            },
        });
    }, [refLocationId]);

    const result = {
        schools,
        schoolsCount,
        schoolsLoading,
        getSchools,
    };

    return result;
};

export { useSchoolsFilter };
