import React from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { useApolloClient, useMutation } from '@apollo/client';
import { Star } from '@material-ui/icons';
import { red } from '@avangard/ui/colors';
import { Autocomplete, Button } from '@avangard/ui/core';

import { routes } from '@config/routes';
import { useNavigate } from '@lib/routing';
import { useEnqueueStacks } from '@modules/layout/hooks';
import { FormStyled } from '@modules/layout/styled';
import { ExtendedFormik, Form } from '@modules/layout/organisms';
import { useCurrentLap, useAccessLaps } from '@modules/lap/hooks';
import { SetCurrentLapMutation } from '@modules/lap/graphql';

import type { LapEntity } from '@modules/lap/entities';
import type {
    SetCurrentLapMutationType,
    SetCurrentLapMutationVariables,
} from '@modules/types/graphql';

type ChangeLapFormProps = {
    onCancel: () => void;
};

const StyledForm = styled(Form)`
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    width: 100%;

    > *:not(:last-child) {
        margin-bottom: 24px;
    }
`;

const FormRowSubmit = styled(FormStyled.FormRowSubmit)`
    justify-content: flex-end;
`;

const LapOption = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const starIcon = <Star style={{ fontSize: 16, color: red[100] }} />;

const ChangeLapForm = (props: ChangeLapFormProps): React.ReactElement => {
    const { onCancel } = props;

    const { t } = useTranslation(['common', 'errors', 'lap']);

    const navigate = useNavigate();
    const apolloClient = useApolloClient();

    const { enqueueSuccess, enqueueError } = useEnqueueStacks();

    const { currentLap, loading: currentLapLoading } = useCurrentLap();

    const { laps, loading: lapsLoading } = useAccessLaps({
        fetchPolicy: 'network-only',
        variables: {
            args: {
                page: { all: true },
            },
        },
    });

    const [setCurrentLap] = useMutation<SetCurrentLapMutationType, SetCurrentLapMutationVariables>(
        SetCurrentLapMutation,
    );

    const initialValues = {
        lap: currentLap,
    };

    return (
        <ExtendedFormik
            enableReinitialize
            validationSchema={yup.object({
                lap: yup.mixed().required(t('errors:validations.required') ?? ''),
            })}
            initialValues={initialValues}
            onSubmit={async values => {
                try {
                    if (!values.lap) {
                        return;
                    }

                    const { data: setCurrentLapData } = await setCurrentLap({
                        variables: { id: values.lap.id },
                    });

                    if (setCurrentLapData?.setCurrentLap) {
                        enqueueSuccess(t('lap:notifiers.change_lap.success'));

                        apolloClient.resetStore();
                        navigate(routes.index.path);
                        onCancel();
                    } else {
                        enqueueError(t('lap:notifiers.change_lap.error'));
                    }
                } catch (e) {
                    throw e;
                }
            }}
        >
            {formikProps => {
                const { values, errors, setFieldValue, isSubmitting } = formikProps;

                const handleChangeLap = (_: React.ChangeEvent<{}>, value: LapEntity | null) =>
                    setFieldValue('lap', value);

                return (
                    <StyledForm>
                        <Autocomplete<LapEntity | null, false, false, false>
                            fullWidth
                            required
                            id='lap'
                            label={t('common:forms.labels.lap') ?? ''}
                            placeholder={t('common:forms.placeholder.choose_lap') ?? ''}
                            options={laps}
                            value={values.lap}
                            error={!!errors.lap}
                            helperText={errors.lap}
                            disabled={currentLapLoading || lapsLoading}
                            getOptionLabel={option => option?.getFullDate() ?? '-'}
                            renderOption={option => (
                                <LapOption>
                                    {option?.getFullDate() ?? '-'}
                                    {option?.isActiveLap(currentLap) ? starIcon : null}
                                </LapOption>
                            )}
                            onChange={handleChangeLap}
                        />

                        <FormRowSubmit>
                            <Button variant='outlined' disabled={isSubmitting} onClick={onCancel}>
                                {t('common:actions.cancel')}
                            </Button>

                            <Button type='submit' loading={isSubmitting}>
                                {t('common:actions.submit')}
                            </Button>
                        </FormRowSubmit>
                    </StyledForm>
                );
            }}
        </ExtendedFormik>
    );
};

export { ChangeLapForm };
