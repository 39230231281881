import { BaseEntity, Entity as DecribeEntity } from '@lib/entity';

import type { EgLessonsListItem } from '@modules/types/graphql';

export type Entity = Partial<EgLessonsListItem> | null;

@DecribeEntity('EgLessonsListItemEntity')
class EgLessonsListItemEntity extends BaseEntity {
    timeRange: string;
    company: number;
    platoon: number;
    platoonX: number;
    id: string;
    name: string;
    color: string;
    instructors: (string | null)[];
    place: string;
    scoresCount: number;
    studentsCount: number;
    averageScore: number;

    constructor(entity: EgLessonsListItem) {
        super(entity);

        this.timeRange = entity?.timeRange ?? '';
        this.company = entity?.company ?? 0;
        this.platoon = entity?.platoon ?? 0;
        this.platoonX = entity?.platoonX ?? 0;
        this.id = entity?.id ?? '';
        this.name = entity?.name ?? '';
        this.color = entity?.color ?? '';
        this.instructors = entity?.instructors ?? [];
        this.place = entity?.place ?? '';
        this.scoresCount = entity?.scoresCount ?? 0;
        this.studentsCount = entity?.studentsCount ?? 0;
        this.averageScore = entity?.averageScore ?? 0;
    }

    hasLesson() {
        const result = this.id !== '';

        return result;
    }

    getAvgScoreLabel() {
        if (this.averageScore === 0) {
            return '';
        }

        return this.averageScore;
    }

    getScoreLabel() {
        if (this.studentsCount === 0) {
            return '';
        }

        const result = this.scoresCount + '/' + this.studentsCount;

        return result;
    }
}

export { EgLessonsListItemEntity };
