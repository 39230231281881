import { gql } from '@apollo/client';

import { LapAccessFragment } from '@modules/lap/graphql';
import { SchoolCollectionFragment } from './school-collection';

export const SchoolSingleFragment = gql`
    fragment SchoolSingle on School {
        ...SchoolCollection
        fullName
        profile
        responsiblePerson
        accessSentAt
        isAccessSent
        attachedUser {
            id
            username
        }
        lapAccessList {
            ...LapAccess
        }
    }
    ${SchoolCollectionFragment}
    ${LapAccessFragment}
`;
