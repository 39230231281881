import { gql } from '@apollo/client';

export const GetCurrentRegionalCenterQuery = gql`
    query GetCurrentRegionalCenter {
        getCurrentRegionalCenter {
            id
            name
            regions {
                id
                name
            }
            location {
                id
                name
            }
        }
    }
`;
