import { sample } from 'effector';

import { setScheduleTypeAction, resetScheduleTypeAction } from '@modules/diary/schedule/models';
import { $scheduleCreateLessonValuesStore, setScheduleCreateLessonValues } from './';

$scheduleCreateLessonValuesStore
    .on(setScheduleCreateLessonValues, (_, payload) => payload)
    .reset(resetScheduleTypeAction);

sample({
    source: setScheduleCreateLessonValues,
    fn: () => 'bulk:create',
    target: setScheduleTypeAction,
});
