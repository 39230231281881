import { split, ApolloClient, InMemoryCache, ApolloLink, HttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { getMainDefinition } from '@apollo/client/utilities';
import { TokenRefreshLink } from 'apollo-link-token-refresh';
import { RestLink } from 'apollo-link-rest';
import { WebSocketLink } from '@apollo/client/link/ws';

import { GRAPHQL_URL, SOCKET_URL, REST_URL } from '@config/environment';
import { i18n } from '@lib/i18n';
import { TokenSession } from '@lib/token';
import { SystemHelper } from '@helpers';
import { RefreshTokenMutation } from '@modules/auth/graphql';
import { Platform } from '@modules/types/graphql';
import { toPromise } from './utils';

import type {
    ApolloClientOptions as BaseApolloClientOptions,
    NormalizedCacheObject,
} from '@apollo/client';
import type { SetTokenSessionPayload } from '@lib/token';

type ApolloClientOptions = Omit<BaseApolloClientOptions<NormalizedCacheObject>, 'cache'>;

const isDev = SystemHelper.isDev();

const noopPromise = new Promise<void>(resolve => resolve());

const createApolloClient = (options: ApolloClientOptions = {}) => {
    const graphqlUri = GRAPHQL_URL;
    const restUri = REST_URL;
    const socketUri = SOCKET_URL;

    const token = TokenSession.getCurrentSession().getAccessToken().getToken();

    const httpLink = new HttpLink({ uri: graphqlUri });

    const wsLink = new WebSocketLink({
        uri: socketUri,
        options: {
            reconnect: true,
            connectionParams: {
                Platform: Platform.center,
                Language: i18n.language,
                Authorization: token ? `${token}` : null,
            },
        },
    });

    const authHttpLink = setContext((_, prevContext) => {
        const headers = { ...prevContext.headers };

        const token = TokenSession.getCurrentSession().getAccessToken().getToken();

        const context = {
            ...prevContext,
            headers: {
                ...headers,
                Language: i18n.language,
                authorization: token ? `Bearer ${token}` : null,
            },
        };

        return context;
    });

    const tokenRefreshLink = new TokenRefreshLink<SetTokenSessionPayload>({
        accessTokenField: 'tokens',

        isTokenValidOrUndefined: () =>
            !TokenSession.getCurrentSession()
                .getAccessToken()
                .isExpiredAfter(60 * 5),

        fetchAccessToken: (): Promise<any> => {
            const refreshToken = TokenSession.getCurrentSession().getRefreshToken();

            if (!refreshToken.issetToken()) {
                return noopPromise;
            }

            const promise = toPromise(
                HttpLink.execute(httpLink, {
                    query: RefreshTokenMutation,
                    variables: {
                        refreshToken: refreshToken.getToken(),
                    },
                }),
            );

            return promise;
        },

        handleFetch: (payload: SetTokenSessionPayload) => {
            TokenSession.setCurrentSession(payload);
        },

        handleResponse: () => (response: any) => {
            const responseData = response?.data?.refreshToken;

            if (responseData?.accessToken && responseData?.refreshToken) {
                return {
                    tokens: {
                        accessToken: responseData.accessToken,
                        refreshToken: responseData.refreshToken,
                    },
                };
            }

            return {
                tokens: {},
            };
        },

        handleError: () => {
            TokenSession.destroyCurrentSession();
        },
    });

    const restLink = new RestLink({
        uri: restUri,
        bodySerializers: {
            fileEncode: (data: any, headers: Headers) => {
                const formData = new FormData();

                formData.append('file', data, data.name);

                return { body: formData, headers };
            },
        },
    });

    // TODO: add to ApolloLink.from when backend can resolve it
    // const batchHttpLink = new BatchHttpLink({
    //     uri: graphqlUri,
    //     batchMax: 6,
    // });

    const splitLink = split(
        ({ query }) => {
            const definition = getMainDefinition(query);

            return (
                definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
            );
        },
        wsLink,
        httpLink,
    );

    const link = ApolloLink.from([tokenRefreshLink, authHttpLink, restLink, splitLink]);

    const apolloClient = new ApolloClient({
        link,
        cache: new InMemoryCache({
            typePolicies: {
                UserGrantsTableItem: {
                    keyFields: () => '',
                },
                LessonScoreCollectionItem: {
                    keyFields: object => `${object.__typename}:${object.studentId}`,
                },
                EvaluationScoreCollectionItem: {
                    keyFields: object => `${object.__typename}:${object.criteriaId}`,
                },
                Evaluation: {
                    keyFields: object => {
                        if (object.id) {
                            return `${object.__typename}:${object.id}`;
                        }

                        return `${object.__typename}:${object.platoonX}_${object.lapId}`;
                    },
                },
            },
        }),
        connectToDevTools: isDev,
        ...options,
    });

    return apolloClient;
};

export { createApolloClient };
