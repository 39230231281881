import { gql } from '@apollo/client';

export const LapFragment = gql`
    fragment Lap on Lap {
        id
        name
        from
        to
        platform
        isCurrent
        course {
            id
            name
        }
        programType
        companyCount
        platoonCount
    }
`;
