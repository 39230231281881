import { useQuery } from '@apollo/client';

import { useEntity } from '@lib/entity';
import { LapEntityList } from '@modules/lap/entities';
import { GetLapsQuery } from '@modules/lap/graphql';

import type { QueryHookOptions } from '@apollo/client';
import type { GetLapsQueryType, GetLapsQueryVariables } from '@modules/types/graphql';

const useLaps = (options?: QueryHookOptions<GetLapsQueryType, GetLapsQueryVariables>) => {
    const queryResult = useQuery<GetLapsQueryType, GetLapsQueryVariables>(GetLapsQuery, options);

    const { entities: laps } = useEntity(
        () => new LapEntityList(queryResult.data?.getLaps ?? null),
        [queryResult.data],
    );

    const result = {
        laps,
        ...queryResult,
    };

    return result;
};

export { useLaps };
