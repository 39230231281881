import { BaseEntity, Entity as DecribeEntity, Relation, RelationList } from '@lib/entity';
import { i18n } from '@lib/i18n';
import { EmployeeEntity, EmployeeEntityList } from '@modules/employees/entities';
import { EgSubjectEntity, TopicEntity } from '@modules/diary/subjects/entities';
import { EgLessonPlaceEntity } from '@modules/diary/lesson-places/entities';
import { LessonTimeEntity } from './lesson-time-entity';

import type { SetLessonFormValues } from '@modules/diary/schedule/requests';
import type { SetDailyEventRequestValues } from '@modules/daily-schedule/requests';
import type { EgLesson } from '@modules/types/graphql';

export type Entity = Partial<EgLesson> | null;

@DecribeEntity('LessonEntity')
class LessonEntity extends BaseEntity {
    id: string;
    company: number;
    platoon: number;
    name: string;
    placeName: string;

    @Relation(() => EgSubjectEntity)
    subject: EgSubjectEntity;

    @Relation(() => TopicEntity)
    topic: TopicEntity;

    @Relation(() => EgLessonPlaceEntity)
    place: EgLessonPlaceEntity;

    @Relation(() => LessonTimeEntity)
    time: LessonTimeEntity;

    @Relation(() => EmployeeEntity)
    instructor: EmployeeEntity;

    @RelationList(() => EmployeeEntityList)
    instructors: EmployeeEntity[];

    constructor(entity: Entity) {
        super(entity);

        this.id = entity?.id ?? '';
        this.name = entity?.name ?? '';
        this.placeName = entity?.placeName ?? '';
        this.company = entity?.company ?? 0;
        this.platoon = entity?.platoon ?? 0;
    }

    exists() {
        const exists = this.id !== '';

        return exists;
    }

    getCompanyPlatoonLabel() {
        if (this.company === 0 || this.platoon === 0) {
            return '-';
        }

        const result = i18n.t('common:blocks.company_and_platoons.company_and_platoon_with_count', {
            company: this.company,
            platoon: this.platoon,
        });

        return result;
    }

    getPreparedValuesForSetEventForm(): SetDailyEventRequestValues {
        const result = {
            name: this.name,
            employees: this.instructors,
            placeName: this.placeName,
        };

        return result;
    }

    getPreparedValuesForSetLessonForm(): SetLessonFormValues {
        const result = {
            subjects: this.subject.exists() ? [this.subject] : [],
            topic: this.topic.exists() ? this.topic : null,
            employees: this.instructors,
            lessonPlace: this.place.exists() ? this.place : null,
        };

        return result;
    }
}

export { LessonEntity };
