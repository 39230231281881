import { createStore, createEvent } from 'effector';
import { useStore } from 'effector-react';

import type { EmployeeEntity } from '@modules/employees/entities';
import type { EgLessonPlaceEntity } from '@modules/diary/lesson-places/entities';
import type { EgSubjectEntity, TopicEntity } from '@modules/diary/subjects/entities';

export type ScheduleCreateLessonValues = {
    subjects: (EgSubjectEntity | null)[];
    topic: TopicEntity | null;
    employees: (EmployeeEntity | null)[];
    lessonPlace: EgLessonPlaceEntity | null;
};

type ScheduleCreateLessonValuesStore = ScheduleCreateLessonValues;

// prettier-ignore
const setScheduleCreateLessonValues = createEvent<ScheduleCreateLessonValues>('set create lesson values');
const $scheduleCreateLessonValuesStore = createStore<ScheduleCreateLessonValuesStore>({
    subjects: [],
    employees: [],
    topic: null,
    lessonPlace: null,
});

const useScheduleCreateLessonValues = (): ScheduleCreateLessonValuesStore =>
    useStore($scheduleCreateLessonValuesStore);

export {
    $scheduleCreateLessonValuesStore,
    useScheduleCreateLessonValues,
    setScheduleCreateLessonValues,
};
