import { createStore, createEvent } from 'effector';
import { useStore } from 'effector-react';

export type DailyScheduleBulkLesson = {
    timeId: string;
    company: number;
    platoon: number;
    id?: string;
};

type DailyScheduleBulkLessonsStore = {
    bulkLessons: (DailyScheduleBulkLesson | null)[];
    hasBulkLessons: boolean;
};

// prettier-ignore
const setDailyScheduleBulkLesson = createEvent<DailyScheduleBulkLesson>('add bulk lesson (if exists), remove if not exists');
const setDailyScheduleBulkLessons = createEvent<DailyScheduleBulkLesson[]>('add list of lessons');
const resetDailyScheduleBulkLessons = createEvent('remove all lessons');
const $dailyScheduleBulkLessonsStore = createStore<DailyScheduleBulkLessonsStore>({
    bulkLessons: [],
    hasBulkLessons: false,
});

const useDailyScheduleBulkLessons = (): DailyScheduleBulkLessonsStore =>
    useStore($dailyScheduleBulkLessonsStore);

export {
    $dailyScheduleBulkLessonsStore,
    useDailyScheduleBulkLessons,
    setDailyScheduleBulkLesson,
    setDailyScheduleBulkLessons,
    resetDailyScheduleBulkLessons,
};
