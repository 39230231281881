import { createStore, createEvent } from 'effector';
import { useStore } from 'effector-react';

// type:action -> bulk:create | bulk:remove | etc
type DailyScheduleTypeActionStore = string;

const setDailyScheduleTypeAction = createEvent<DailyScheduleTypeActionStore>(
    'set daily schedule type action',
);
const resetDailyScheduleTypeAction = createEvent('reset daily schedule type action');
const $dailyScheduleTypeActionStore = createStore<DailyScheduleTypeActionStore>('');

const useDailyScheduleTypeAction = (): DailyScheduleTypeActionStore =>
    useStore($dailyScheduleTypeActionStore);

export {
    $dailyScheduleTypeActionStore,
    useDailyScheduleTypeAction,
    setDailyScheduleTypeAction,
    resetDailyScheduleTypeAction,
};
